
export const storeUser = user => ({
  type: "STORE_USER",
  payload: {
    user
  }
});

export const storeOtherUsers = user => ({
  type: "STORE_OTHER_USERS",
  payload: {
    user
  }
});

export const setCurrentOrg = currentOrg => ({
  type: "SET_CURRENT_ORG",
  payload: {
    currentOrg
  }
});

export const resetUser = () => ({
  type: "RESET_USER",
  payload: {}
});

export const setWorldToEdit = worldID => ({
  type: "SET_WORLD_TO_EDIT",
  payload: {
    worldID
  }
});

export const storeWorldsForUser = worlds => ({
  type: "STORE_WORLDS_FOR_USER",
  payload: {
    worlds
  }
});

export const storeAllWorlds = worlds => ({
  type: "STORE_ALL_WORLDS",
  payload: {
    worlds
  }
});

export const addError = error => ({
  type: "ADD_ERROR",
  payload: {
    error
  }
});

export const removeError = key => ({
  type: "REMOVE_ERROR",
  payload: {
    key
  }
});

export const addActivityIndicator = message => ({
  type: "ADD_ACTIVITY_INDICATOR",
  payload: {
    message
  }
});