import React, { useState, useEffect, useRef } from 'react';

import WorldAPIClient from '../../../boxappapi/world_client'

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import InputField from '../../../lib/helpers/InputField'

const AutoRotate = (props) => {

	const initialValue = props.settings
		&& (+props.settings.auto_rotate
			|| 0)
	const [rotate, setRotate] = useState(initialValue)
	const initialized = useRef(false)

	useEffect(() => {
		if (initialized.current) {

			const _timeout = setTimeout(async () => {
				props.addActivityIndicator('Updating Settings..')

				try {
					const API = new WorldAPIClient(props.user.token);
					const response = await API.updateWorldAdvancedSettings(props.worldID, {
						auto_rotate: +rotate
					})

					if (true === response.success) {
						setRotate(+response.advanced_settings.auto_rotate)
						props.addActivityIndicator('')
						props.updatePreview()
					}
				} catch(err) {
					console.error(err)
					props.addError(err)
					props.addActivityIndicator('')
				}
			}, 1000);

			return () => { clearTimeout(_timeout) }
		} else {
			initialized.current = true
		}
	}, [rotate])

	return (<div>
		<InputField
			label={'Auto-Rotate:'}
			type={'checkbox'}
			name={'auto_rotate'}
			id={'auto_rotate'}
			value={1}
			checked={(!initialized.current) ? initialValue : rotate}
			onChange={e => setRotate(e.target.checked ? 1 : 0)} />
	</div>)
}

export default connect(getStore, {
  addActivityIndicator,
  addError,
})(AutoRotate);
