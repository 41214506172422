import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {updateUser, getPublicUser, deleteUser} from '../../../boxappapi/user_client'
import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'
import InputField from '../../../lib/helpers/InputField'

import Header from '../../../components/Header'

import {
  PageH1,
  Spacer
} from '../../../lib/styles/typography'

import {
  MaxWidthContent
} from '../../../lib/styles/layout'

import {
  Button,
  DeleteButton
} from '../../../lib/styles/forms'

import UserAvatar from '../../../components/UserAvatar/UserAvatar'

import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addError,
  addActivityIndicator,
} from "../../../redux/actions"

import './UserProfile.scss'


class UserProfile extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: null,
      newPassword: '',
      verifyPassword: '',
      roles: 'author',
      userRemoved: false,
    }
  }

  componentWillMount() {
    this.props.addActivityIndicator('Fetching user profile..')
    this.props.user.authenticated
      && this.loadUser()
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.user !== this.props.user
      && this.props.user.authenticated) {
      this.loadUser()
    }
  }

  render() {

    if (this.state.logUserOut) {
      return (<Redirect to={'/logout'} />)
    }

    if (this.state.userRemoved) {
      return (<Redirect to={'/org/manage-users'} />)
    }

    if (!this.state.user) {
      return (<LoadingIndicator message={'Fetching your profile...'} />)
    }

    return (<MaxWidthContent>
      <Header
        left
        title={`@${this.state.user.username}`}
      />

      <PageH1>{this.state.user.name}'s Profile</PageH1>
      <MaxWidthContent
        width={`800px`}
        padding={`1em 2em 3em`}
        height={`auto`}
      >
        <div className={"avatar-wrapper"}>
          <DragNDrop
            onDrop={this.handleProfilePicUpdate.bind(this)}>
            <UserAvatar
              link={false}
              size={96}
              userObj={this.state.user} />
          </DragNDrop>
        </div>

        <form
          onSubmit={this.handleSubmit.bind(this)}
          className={'user-profile-form'}
          autoComplete={'off'}>

          <InputField
            label={'Update Name:'}
            type={'text'}
            name={'name'}
            autoComplete={'off'}
            value={this.state.user.name}
            onChange={this.updateState.bind(this)} />

          <InputField
            label={'Update Email:'}
            type={'email'}
            name={'email'}
            autoComplete={'off'}
            value={this.state.user.email || ''}
            onChange={this.updateState.bind(this)} />

          {this.props.user.isAdmin
            && (+this.props.user.id !== +this.state.user.id)
            && <InputField
              label={'Role:'}
              type={'select'}
              name={'roles[]'}
              value={this.state.user.roles[0]}
              onChange={(e) => {
                const {target} = e;
                const roles = [target.value]
                this.setState({
                  user: Object.assign({}, this.state.user, {roles})
                })
              }}
              options={[<option key="author" value="author">Author</option>,
                <option key="administrator" value="administrator">Admin</option>]} />}

          <InputField
            label={'Description:'}
            type={'textarea'}
            name={'description'}
            autoComplete={'off'}
            value={this.state.user.description || ''}
            onChange={this.updateState.bind(this)} />

          <InputField
            label={'Change Password:'}
            type={'password'}
            name={'newPassword'}
            autoComplete={'off'}
            value={this.state.newPassword}
            onChange={(e) => this.setState({newPassword: e.target.value})} />

          <InputField
            label={'Verify Password:'}
            type={'password'}
            name={'verifyPassword'}
            autoComplete={'off'}
            value={this.state.verifyPassword}
            onChange={(e) => this.setState({verifyPassword: e.target.value})} />

          <InputField
            label={'Use Two Factor Authentication:'}
            type={'checkbox'}
            name={'2fa_on'}
            autoComplete={'off'}
            value={this.state.user['2fa_on']}
            checked={!!this.state.user['2fa_on']}
            onChange={(e) => {
              const newState = this.state
              newState.user['2fa_on'] = e.target.checked ? true : false;
              this.setState(newState);
            }} />

          <DeleteButton
            type={'button'}
            small
            onClick={this.deleteUser.bind(this)}
          >
            Delete User
          </DeleteButton>
          <Spacer
            inline
            width={`1em`}
          />
          <Button
            type={'submit'}
            small
          >
            Update Profile
          </Button>
        </form>
      </MaxWidthContent>
    </MaxWidthContent>);
  }

  updateState(input) {
    const { target } = input;
    const newState = {}
    newState[target.name] = target.value
    this.setState({
      user: Object.assign({}, this.state.user, newState)
    })
  }

  async deleteUser() {
    this.props.addActivityIndicator('Removing user..')
    try {
      const confirm = await deleteUser(this.state.user.id, this.props.user.token)
      if (confirm.success) {
        this.setState({userRemoved: true})
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
    }
    this.props.addActivityIndicator('')
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (0 < this.state.newPassword.length
      && 8 > this.state.newPassword.length) {
      this.props.addError({ message: 'Passwords must be at aleast 8 characters long.'})
      this.props.addActivityIndicator('')
      return;
    }

    if (this.state.newPassword !== this.state.verifyPassword) {
      this.props.addError({ message: 'Passwords must match.'})
      this.props.addActivityIndicator('')
      return;
    }

    if ('' !== this.state.newPassword) {
      const logout = window.confirm('Changing your password will log you out. Is that OK? You will have to log back in using the new password.')
      if (!logout) {
        return;
      }
    }

    this.props.addActivityIndicator('Updating your profile..')

    try {
      const data = {
        name: this.state.user.name,
        email: this.state.user.email,
        description: this.state.user.description,
      }

      if ('' !== this.state.newPassword) {
        data.password = this.state.newPassword
      }

      data['2fa_on'] = this.state.user['2fa_on']

      if (this.props.user.isAdmin
        && (+this.props.user.id !== +this.state.user.id)) {
        data.roles = this.state.user.roles.join(',')
      }

      const user = await updateUser(this.state.user.id, data, this.props.user.token)

      if (user && this.state.user.id === user.id) {
        const logUserOut = (data.password && '' !== data.password) ? true : false;
        this.setState({ user: user, logUserOut })
        this.props.addActivityIndicator('')
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
      this.props.addActivityIndicator('')
    }
  }

  async handleProfilePicUpdate(files) {
    const pic = files[0];

    this.props.addActivityIndicator('Updating your avatar..')

    try {
      const data = {
        profile_picture: pic
      }

      const user = await updateUser(this.state.user.id, data, this.props.user.token)

      if (user && this.state.user.id === user.id) {
        const logUserOut = (data.password && '' !== data.password) ? true : false;
        this.setState({ user: user, logUserOut })
        this.props.addActivityIndicator('')
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
      this.props.addActivityIndicator('')
    }
  }

  async loadUser() {
    const userID = this.props.match.params.user_id

    try {
      const fullUser = await getPublicUser(+userID)

      this.setState({
        user: fullUser.user,
      })
      this.props.addActivityIndicator('')
    } catch(err) {
      console.error(err)
      this.props.addError(err)
      this.props.addActivityIndicator('')
    }
  }
}

export default connect(getStore, {
  addError,
  addActivityIndicator
})(UserProfile);
