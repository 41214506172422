import styled from 'styled-components'

import {
	Colors
} from '../../../lib/styles/styles'

export const BottomNavWrapper = styled.div`
	position: fixed;
	display: block;
	width: 100%;
	z-index: 999999;
	bottom: 0;
	left: 0;
	padding: 5px;
	text-align: center;
	box-sizing: border-box;
	background-color: ${Colors.opaqueWhite};
	border-top: 0.1em solid ${Colors.lightGray};

	&:before,
	&:after {
		content: '';
		display: block;
		clear: both;
	}
`

export const BNLink = styled.div`
	display: inline-block;

	float: ${props => props.left
		? 'left'
		: props.right
			? 'right'
			: 'none'};
`

export const Icon = styled.img`
	box-sizing: border-box;

	width: 2em;
	// img {
	// 	display: inline-block;
	// 	max-width: 100%;
	// 	height: auto;
	// }

	@media screen and (min-width: 750px) {
			width: 2.5em;
	}

	// @media screen and (min-width: 800px) {
	// 		width: 3em;
	// }

	@media screen and (min-width: 1400px) {
			width: 4em;
			padding: 0.5em;
	}
`