import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './SubNav.css'

class SubNav extends Component {
  render() {
    return (<div className={'sub-nav'}>
	    {this.props.links.map((link, i) => {
	    	if ('string' === typeof link) {
	    		return (<span key={i}>{link}</span>)
	    	} else {
			  	const active = (this.props.location
			  		&& link.to === this.props.location.pathname)
		    	return (<Link
		    		className={`${(active) ? 'active ' : ''}link`}
		    		key={i}
		    		to={link.to}>
		    		{link.text}
		    	</Link>)
	    	}
	    })}
	  </div>)
  }
}

export default SubNav;