import styled from 'styled-components'

import {Colors} from '../../../lib/styles/styles'

export const SlideInNavWrapper = styled.div`
	position: relative;
  z-index: 999;
`

export const SlideInNavBtn = styled.div`
	float: ${props => props.direction || 'left'};

  display: block;
  position: relative;
  width: 1.8em;
  height: 1.25em;
  margin-left: 0.75em;
  cursor: pointer;
  padding: 0.5em 0 0.25em 0.25em;

  .menu-bar {
    display: block;
    background-color: #444;
    height: 0.25em;
    margin-bottom: 0.5em;
    border-radius: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const SlideInNavCont = styled.div`
	height: ${props => props.height}px;

	${props => 'left' === props.direction
		? 'left: -100%;'
		: 'right: -100%;'}

  position: fixed;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  min-width: 280px;
  transition: all .4s ease-in-out;
  overflow: auto;
  text-align: left;
  width: ${props => props.size || 90}%;

  &.open {
    visibility: visible;
    opacity: 1;
    bottom: 0;
    // height: 100vh;
    margin-top: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.975);

    // border-right: 1px solid #ccc;
    // border-left: 1px solid #ccc;

    // box-shadow: -1em 8em 15em ${Colors.darkGray};

    ${props => 'left' === props.direction
	  		? 'left: 0;'
	  		: 'right: 0;'}
  }

  .link {
    display: block;
    padding: 0.75em 1em;
    border-bottom: 1px solid #f4f4f4;
  }

  @media screen and (min-width: 600px) {
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    width: 35%;
  }
`