import React, { useState, useEffect, useRef } from 'react';

import WorldAPIClient from '../../../boxappapi/world_client'

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import InputField from '../../../lib/helpers/InputField'

const TripodWatermarkSize = (props) => {
	const initialTwmSize = props.settings
		&& (props.settings.tripod_watermark_size
			|| 1)
	const [size, setSize] = useState(initialTwmSize)
	const initialized = useRef(false)

	useEffect(() => {
		if (initialized.current) {

			const _timeout = setTimeout(async () => {
				props.addActivityIndicator('Updating Settings..')

				try {
					const API = new WorldAPIClient(props.user.token);
					const response = await API.updateWorldAdvancedSettings(props.worldID, {
						tripod_watermark_size: size
					})

					if (true === response.success) {
						setSize(response.advanced_settings.tripod_watermark_size || 1)
						props.addActivityIndicator('')
						props.updatePreview()
					}
				} catch(err) {
					console.error(err)
					props.addError(err)
					props.addActivityIndicator('')
				}
			}, 1000);

			return () => { clearTimeout(_timeout) }
		} else {
			initialized.current = true
		}
	}, [size])

	return (<div>
		<InputField
			label={'Resize Tripod Watermark:'}
			description={size}
			type={'range'}
			name={'tripodWatermarkSize'}
			min={0.25}
			max={4}
			step={0.25}
			id={'tripodWatermarkSize'}
			value={size}
			onChange={e => setSize(e.target.value)} />
	</div>)
}

export default connect(getStore, {
  addActivityIndicator,
  addError,
})(TripodWatermarkSize);
