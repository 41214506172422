import React from 'react'
import UserAvatar from '../UserAvatar/UserAvatar'

import {
	TeaserBoxWrapper,
	TeaserBoxBackground,
	TeaserBoxContent,
	TeaserBoxTitle,
	TeaserBoxLink,
	TeaserBoxDescription,
	AvatarWrapper
} from './TeaserBox.styles'

import {
  Gradient
} from '../../lib/styles/styles'

function TeaserBox(props) {

	const {
		link,
		background,
		user,
		title,
		description,
		landscape
	} = props

	return (<TeaserBoxWrapper className={props.className}>

		<TeaserBoxContent>
			<TeaserBoxTitle>
				{title}
			</TeaserBoxTitle>
			{description
				&& <TeaserBoxDescription>
						<div>{description}</div>
					</TeaserBoxDescription>}
		</TeaserBoxContent>

		{user
			&& <AvatarWrapper>
				<UserAvatar
		      showName={true}
		      link={true}
		      userObj={user}
		      size={48}
		    />
		    <Gradient
					end={'rgba(0, 0, 0, 0.25)'}
					start={'rgba(255, 255, 255, 0)'}
					height={'100%'}
				/>
	     </AvatarWrapper>}

		{link && <TeaserBoxLink href={link} />}

		<TeaserBoxBackground
			image={background}
			landscape={landscape} />

		<Gradient
			start={'rgba(0, 0, 0, 0.5)'}
			end={'rgba(255, 255, 255, 0)'}
			height={'100%'} />
	</TeaserBoxWrapper>)
}

export default TeaserBox