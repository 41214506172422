import React, { Component } from 'react';
import api from '../../../boxappapi/index'
// import WorldAPIClient from '../../../boxappapi/world_client'
import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'

class WorldPreview extends Component {

  constructor(props) {
    super(props)

    this.state = {
      world: props.world || null,
      vrUrl: `${api.getVRURL(props.world.ID)}&admin=true`,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.timestamp !== prevProps.timestamp) {
      // console.log(this.props.world)
      this.iframe.contentWindow.postMessage({
        action: this.props.action,
      }, '*')
    }
  }

  render() {
    if (!this.props.world) {
      return (<LoadingIndicator message={'Loading world.'} />)
    }

    const allowParams = [
      'accelerometer;',
      'gyroscope'
    ];

    return (
      <div className="Edit-Mode">
        <iframe
          id={`world-preview`}
          title={this.props.world.post_title}
          src={`${this.state.vrUrl}`}
          frameBorder={0}
          allow={allowParams.join(' ')}
          allowFullScreen={true}
          ref={(iframe) => this.iframe = iframe}></iframe>
      </div>
    );
  }
}

export default WorldPreview
