import styled from 'styled-components'
import {
	Colors
} from '../../lib/styles/styles'

export const Intro = styled.div`
	padding: 3em 2em;
	text-align: center;
	color: ${Colors.gray};
`

export const NoResults = styled(Intro)`
	color: ${Colors.darkGray};
`