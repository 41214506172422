import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import api from '../../../boxappapi/index'
import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"

import AdminPage from "../../../components/AdminPage/AdminPage"
import {PageH1} from "../../../lib/styles/typography"
import {PageContent} from "../../../lib/styles/layout"
import EditOrganizationForm from './EditOrganizationForm'
import OrgSubNav from '../SubNav/OrgSubNav'
import BottomNav from "../../../components/Navigation/BottomNav"

class EditOrganizationPage extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
      organization: null,
    }
  }

  componentWillMount() {
    this.loadOrganization()
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.currentUser !== prevProps.user.currentUser) {
      this.loadOrganization()
    }
  }

  render() {

    const _org = this.props.user.currentUser
      ? api.getOrgbyName(api.org, this.props.user.currentUser.orgs)
      : null
    const orgName = _org ? _org.blogname : ''

    return (<AdminPage isOrg isAdmin>
      <PageContent>
      {this.state.organization
        ? <div className={`Edit-Organization`}>
            <OrgSubNav location={this.props.location} />
            <PageH1>{orgName}</PageH1>
            <EditOrganizationForm />
          </div>
        : <p>Loading your organization.</p>}
      </PageContent>
      <BottomNav />
    </AdminPage>)
  }

  loadOrganization() {
    const organization = this.props.user.currentUser
      ? api.getOrgbyName(api.org, this.props.user.currentUser.orgs)
      : null

    this.setState({
      organization: organization
    })
  }
}

export default connect(getStore, {})(EditOrganizationPage);
