import React from 'react';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"

import {
	SuccessMessage,
} from './RegistrationForm.components'
import RegistrationFormSuccessMessage from './RegistrationFormSuccessMessage'

import {
	RegFormWrapper,
} from '../../Register/styles'

const RegistrationFormSuccess = (props) => {

	return (<RegFormWrapper
		bgColor={`rgba(10,10,10,0.7)`}
	>
		<SuccessMessage>
				<RegistrationFormSuccessMessage
					goToLogin={props.goToLogin}
				/>
		</SuccessMessage>
	</RegFormWrapper>)
}

export default connect(getStore, {})(RegistrationFormSuccess);
