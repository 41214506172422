import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import useBreadCrumbs from '../../../lib/hooks/useBreadCrumbs'

import {
	NavHeader,
	BackArrow,
	NavContent,
	HeaderTitle,
	NavContainer,
} from './SlidingTreeNav.components'

import BreadCrumbs from './BreadCrumbs'

import NavItem from './NavItem'

const NavItemType = (<NavItem />).type;

const SlidingTreeNav = (props) => {

	const [crumbs, addCrumb, reduceCrumb] = useBreadCrumbs()

	const [content, setContent] = useState(null)
	const [activeNav, setActiveNav] = useState(null)

	useEffect(() => {
		setContent( _getNavItems )
	}, [])

	useEffect(() => {
		if (0 < crumbs.length) {
			const __nav = crumbs.reverse()[0]
			setActiveNav(__nav)
			setContent(__nav.children)
		} else {
			// reset
			_resetNav()
		}
	}, [crumbs])

	const {children} = props

	//
	const _setActiveNav = (navProps) => {
		const nav = navProps
		addCrumb(nav)
	}

	//
	const _resetNav = () => {
		setContent( _getNavItems )
		setActiveNav( null )
	}

	//
	const _cloneNavItems = (child) => {
		if (!child) return null;

		const _props = {...child.props} || {}
		let _children = _props.children || []

		if (child.type && NavItemType === child.type) {
			_props.openNav = _setActiveNav
			_children = React.Children.map(_props.children, _cloneNavItems)
		}

		return React.cloneElement( child, _props, _children )
	}

	//
	const _getNavItems = React.Children.map(children, _cloneNavItems)

	return (<NavContainer>
			<BreadCrumbs
				crumbs={crumbs}
			/>

			{activeNav
				&& <NavHeader>
					<BackArrow onClick={(e) => {
							e.preventDefault()
							reduceCrumb();
						}} />

					<HeaderTitle>
						<h3>{activeNav && activeNav.title}</h3>
					</HeaderTitle>
				</NavHeader>}


		<NavContent>
			{content}
		</NavContent>
	</NavContainer>)
}


export default connect(getStore, {
  addActivityIndicator,
  addError,
})(SlidingTreeNav);
