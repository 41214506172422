import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
// import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  storeWorldsForUser,
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import api from '../../../boxappapi/index'
import WorldAPIClient from '../../../boxappapi/world_client'
import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'
import Header from '../../../components/Header'
import WorldPreview from './WorldPreview'
import EditWorldSidebar from './EditWorldSidebar'
import './EditWorldPage.scss'

import {
  WorldSharingButtons,
  WorldSharingButtonsWrapper
} from './styles'

class EditWorldPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      world: null,
      timestamp: Date.now() || 0,
      action: 'none',
      open: true,
    }
  }

  componentWillMount() {
    this.loadWorld()
    this.getListOfWorldsForUser()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user.id !== prevProps.user.id) {
      this.loadWorld()
      this.getListOfWorldsForUser()
    }
  }

  goBack() {this.props.history.goBack()}

  render() {

    if (!this.state.world) {
      return (<LoadingIndicator message={'Loading page.'} />)
    }

    const worldUrl = api.getVRURL(this.state.world.ID)
    return (
      <div
        className="Edit-World">

        <Header
          history={this.props.history}
          left
          right={() => (<>
            <WorldSharingButtons worldUrl={worldUrl} />
            <WorldSharingButtonsWrapper>
            <EditWorldSidebar
              updatePreview={this.updateWorld.bind(this)}
              world={this.state.world}
              user={this.props.user}
              goBack={this.goBack.bind(this)}
              />
            </WorldSharingButtonsWrapper>
          </>)}
        />

      	<div className={`edit-world-preview ${(!this.state.open) ? 'full-width' : ''}`}>
      		<WorldPreview
      			timestamp={this.state.timestamp}
      			world={this.state.world}
            action={this.state.action}
      			user={this.props.user} />
      	</div>
      </div>
    );
  }

  updateWorld(action) {
    action = action || 'world'
  	this.setState({
      timestamp: Date.now() || (+this.state.timestamp + 1),
      action,
    });
  }

  getHeight() {
    const windowHeight = window.innerHeight
    const headerHeight = document.getElementById('header').clientHeight

    return { height: (windowHeight - headerHeight) }
  }

  updateStateWorld(world) {
    this.setState({world: null})
    if (+this.props.match.params.worldId
      !== +world.ID) {
      // world id in url does not match the one from server
      return;
    }

    if (!this.props.user.currentUser.isAdmin
      && +this.props.user.id
      !== +world.post_author) {
      // user does not own this post
      const err = {
        message: 'You cannot edit a world if it is not your own.',
        code: 401,
      }
      this.displayError(err)
    } else {
      this.setState({
        world: world,
      })
      this.props.addActivityIndicator('')
    }
  }

  displayError(err) {
    console.error(err.message)
    this.props.addError(err)
    this.props.addActivityIndicator('')
  }

  async loadWorld() {
    this.props.addActivityIndicator('loading world.')

    if(this.props.user
      && (0 !== this.props.user.id)
      && '0' !== this.props.match.params.worldId) {

      try {
        const world = await WorldAPIClient.getWorld(this.props.match.params.worldId, this.props.user.token)
        this.updateStateWorld(world)
      } catch(err) {
        this.displayError(err)
      }
    }
  }

  async getListOfWorldsForUser() {

    // we need the user to get worlds for user
    // so if no user id is avail, abort
    if(!this.props.user
      || !this.props.user.id) {
      return;
    }
    try {

      // get the worlds per user id
      const worlds = await WorldAPIClient.getWorldList({
        ids: [], // an empty array gets all worlds, not just a set of ids
        author: this.props.user.id,
        perPage: -1 // -1 indicates all worlds in one page
      })

      if (worlds.worlds && 0 < worlds.worlds.length) {
        // console.log('New worlds loaded:', response.data.worlds)
        this.setState({worldList: worlds.worlds,})
        this.props.storeWorldsForUser(worlds.worlds)
      }
    } catch (err) {
      this.displayError(err)
    }
  }

  async deleteWorld(e) {
    e.preventDefault();

    const confirm = window.confirm('Are you sure you want to delete this world?');

    if (!confirm)
      return;

    this.props.addActivityIndicator('Deleting world.')

    try {
      const API = new WorldAPIClient(this.props.user.token);
      const response = await API.deleteWorld(this.state.world.ID)
      if (response.data
        && true === response.data.success) {
        this.setState({worldDeleted: true})
        this.props.addActivityIndicator('')
      }
    } catch (err) {
      this.displayError(err)
    }
  }
}

export default connect(getStore, {
  storeWorldsForUser,
  addActivityIndicator,
  addError
})(EditWorldPage);
