import React from 'react';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import TripodWatermarkSize from './TripodWatermarkSize'
import AutoRotate from './AutoRotate'

const AdvancedSettings = (props) => {

	return (<div>
		<TripodWatermarkSize
			worldID={props.worldID}
	    settings={props.settings}
	    updatePreview={props.updatePreview} />

	  <AutoRotate
			worldID={props.worldID}
	    settings={props.settings}
	    updatePreview={props.updatePreview} />
	</div>)
}

export default connect(getStore, {
  addActivityIndicator,
  addError,
})(AdvancedSettings);
