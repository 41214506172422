const initialState = {
  message: '',
}

const activityIndicator = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ACTIVITY_INDICATOR": {
		  const { message } = action.payload
      return Object.assign({}, state, {message});
    }

    default: {
      return state;
    }
  }
};

export default activityIndicator;
