import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";

import api from '../../boxappapi/index'
import { connect } from 'react-redux'
import {addError} from "../../redux/actions"
import {getStore} from "../../redux/selectors"
import BoxappCookie from '../../BoxappCookie'

class AdminPage extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
  		granted: false,
      loading: false,
  	}
  }

  componentWillMount() {
    if (BoxappCookie.get('boxappUser')
      && null === this.props.user.currentUser) {
      // we have a logged in user
      this.setState({
        loading: true,
      })
    } else {
      this.checkPermissions()
    }
  }

  componentDidUpdate(prevProps) {
  	if (prevProps.user !== this.props.user) {
  		this.checkPermissions()
  	}
  }

  render() {

    if (this.state.loading) {
      return (<p>Checking your permissions.</p>)
    }

  	if (!this.state.granted) {
  		return (<p>You do not have permissions to access this page.</p>)
  	}

    return (<React.Fragment>
    		{this.props.children}
    	</React.Fragment>)
  }

  checkPermissions() {
  	if (this.props.isAdmin
  		&& this.props.isOrg) {
  		this.setState({
	  		granted: (this.props.user.isAdmin
	  			&& ('' !== api.org))
	  	}, this.checkState.bind(this))
	  	return
  	}
  	// check that user is admin
  	if (this.props.isAdmin) {
	  	this.setState({
	  		granted: this.props.user.isAdmin
	  	}, this.checkState.bind(this))
	  	return
  	}
  	// check that it is an organization
  	if (this.props.isOrg) {
	  	this.setState({
	  		granted: ('' !== api.org)
	  	}, this.checkState.bind(this))
	  	return
  	}
  	// by default just check that the user is authenticated
  	this.setState({
  		granted: this.props.user.authenticated
  	}, this.checkState.bind(this))
  }

  checkState() {
    this.setState({loading: false})
  }
}

export default connect(getStore, {addError})(AdminPage);
