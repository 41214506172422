import { useState, useEffect } from 'react';

let __crumbs = [];

function useBreadCrumbs() {
	const [crumbs, setCrumbs] = useState(__crumbs);

	const addCrumb = (crumb) => {
		const newCrumbs = [...__crumbs, crumb]
		setCrumbs(newCrumbs)
	}

	const reduceCrumb = () => {
		let currCrumbs = [...__crumbs];
		currCrumbs.pop()
		setCrumbs(currCrumbs)
	}

	useEffect(() => {
		__crumbs = crumbs
	})

  return [crumbs, addCrumb, reduceCrumb]
}

export default useBreadCrumbs