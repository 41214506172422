import React, { Component } from 'react';
import {
  Input,
  Button,
  Select,
  Textarea,
  Label,
} from '../styles/forms'

class InputField extends Component {

  constructor(props) {
    super(props)

    this.state = {
      type: props.type || 'text',
      name: props.name,
      id: props.id || props.name,
    }
  }

  componentWillMount() {

  }

  render() {
    return (
      <div className={`InputField ${this.props.type} ${this.props.wrapperClass || ''}`}>

        {this.props.label
          && this.labelElement()}
        {this.props.description
          && <i>{this.props.description}</i>}
        {this.getElement()}
      </div>
    )
  }

  labelElement() {
    return (<Label htmlFor={this.state.id}>{this.props.label}</Label>)
  }

  getInput() {
    return (<Input
      {...this.props} />)
  }

  getTextarea() {
    return (<Textarea
      {...this.props} />)
  }

  getButton() {
    return (<Button
      {...this.props}>
        {this.props.value}
      </Button>)
  }

  getSelect() {
    return (<Select
      {...this.props}>
        {this.props.options}
      </Select>)
  }

  getCheckboxButton() {
    return (<Button {...this.props}>
        {this.props.value}
      </Button>)
  }

  getElement() {
    let el = null;
    switch(this.props.type){
      case 'textarea' :
        el = this.getTextarea()
      break;

      case 'button' :
        el = this.getButton()
      break;

      case 'select' :
        el = this.getSelect()
      break;

      default :
        el = this.getInput()
      break;
    }

    return el;
  }
}

export default InputField