import React, { Component } from 'react';
// redux
import { connect } from 'react-redux'
import {addError, addActivityIndicator} from "../../../redux/actions"
// api
import {getCategories} from '../../../boxappapi/tax_client'
// components
import InputField from '../../../lib/helpers/InputField'
import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'

import {Button} from '../../../lib/styles/forms'

class EditCategories extends Component {

  constructor(props) {
    super(props)

    this.state = {
      worldID: props.worldID || 0,
      categories: [],
      catsSelected: [],
    }

    this.timeInterval = null;
    this.resetKeyUp = false;
  }

  componentWillMount() {
    this.fetchCategories()
  }

  render() {
    if (0 === this.state.categories.length) {
      return <LoadingIndicator />
    }
    return (
      <div className="edit-categories">
        {0 < this.state.categories.length
          && this.state.categories.map((cat, i) => {
            return (<InputField
              key={cat.id}
              label={cat.name}
              name={`${cat.taxonomy}[]`}
              id={cat.slug}
              type={'checkbox'}
              checked={this.isChecked(cat.id)}
              value={cat.id}
              onChange={this.updateTax.bind(this)} />)
          })}

        <Button
          small
          onClick={this.saveTax.bind(this)}
        >
          Save
        </Button>
      </div>
    );
  }

  updateTax(e) {
    const {target} = e;
    const catId = +target.value
    let catsSelected = this.state.catsSelected;

    if(target.checked) {
      // adding
      catsSelected = [...catsSelected, catId]
    } else {
      const index = catsSelected.indexOf(catId)
      catsSelected.splice(index, 1)
    }
    this.setState({ catsSelected })
  }

  // checkWhenToSave() {

  //   if (this.resetKeyUp) {
  //     // this prevents multiple API calls when the user is typing
  //     return
  //   }

  //   this.resetKeyUp = true;
  //   // use this state to indicate any loading animation, etc
  //   this.setState({processing: true,})
  //   this.timeInterval = setTimeout(this.saveTax.bind(this), 3000);
  // }

  saveTax() {
    // clearTimeout()
    this.props.updateWorld({category: this.state.catsSelected})
    // this.resetKeyUp = false
  }

  isChecked(id) {
    const test = this.state.catsSelected.indexOf(id)

    return (-1 !== test) ? true : false
  }

  async fetchCategories() {

    try {
      const categories = await getCategories()
      this.setState({categories})

      this.fetchCategoriesForWorld()
    } catch(err) {
      console.error(err)
      this.props.addError(err)
    }
  }

  async fetchCategoriesForWorld() {

    try {
      const cats = await getCategories(this.state.worldID)
      if (0 < cats.length) {
        const catsSelected = cats.map(cat => {return cat.id})
        this.setState({catsSelected})
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
    }
  }
}

export default connect(null, {addError, addActivityIndicator})(EditCategories);