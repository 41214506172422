import React, {  } from 'react';
import styled from 'styled-components'


const LoadingIndicator = (props) => {

  return (
    <LodingWrapper {...props}>
      {!props.noIcon
        && <Icon />}

      {props.message
        && <p>{props.message}</p>}
    </LodingWrapper>
  )
}

const LodingWrapper = styled.div`
  padding: ${props => props.padding || '30% 20%'};
  text-align: center;

  @media screen and (min-width: 1400px) {
    padding-top: 20%;
  }
`

const Icon = styled.div`
  display: inline-block;
  position: relative;
  width: 3em;
  height: 3em;
  animation: spin 2s linear infinite;
  background-color: #444;
  border-radius: 5%;
  opacity: 0.15;

  &:before {
    position: absolute;
    top: 10px;
    left: 10px;
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 5%;
  }

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
`

export default LoadingIndicator