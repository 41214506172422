// dependencies
import React, { useState } from 'react';
import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
// components
import InputField from '../../../lib/helpers/InputField'
// import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop'
//
import {
  DeleteButton,
  Button,
} from '../../../lib/styles/forms'
//
import {
  AlignRight,
  Spacer
} from '../../../lib/styles/layout'

function EditNavButton(props) {

  const [navButton, setNavButton] = useState(props.navButton)
  // used only to store new media files
  // const [media, setMedia] = useState(null)

  const saveNavButton = async () => {
    const newTT = {
      id: navButton.id,
      world_id: props.worldID,
      name: navButton.name,
      location_id: navButton.location_id,
    }

    const newTTs = await props.onSave(newTT)
    return newTTs
    // setNavButton(newTTs)
  }

  const deleteNavButton = (e) => {
    e.preventDefault();
    props.onDelete(navButton.id)
  }


  const getLocationOptions = () => {
    // start with an empty option
    let locations = [<option key={-1} value={null}>Select a World</option>];
    // build the rest of the select dropdown options
    for (var i = props.world.myWorlds.length - 1; i >= 0; i--) {
      locations = [...locations, <option
        key={i}
        value={props.world.myWorlds[i].ID}>
        {props.world.myWorlds[i].post_title}
      </option>]
    }
    // must return an aray of React Symbols
    return locations
  }

  if (!navButton
    || 'undefined' === typeof navButton.id) {
    return null;
  }

  return (
    <div className="edit-navButton">
      <AlignRight>
        <i>
          <a href={'#close'}
            onClick={e => {
              e.preventDefault();
              props.closeNavButton()
            }}
          >
            Close this nav button
          </a>
        </i>
      </AlignRight>

      <h4>{(0 !== navButton.id
        && '' !== navButton.name)
        ? navButton.name
        : `New NavButton`}</h4>

      <div>
        <InputField
          type={'text'}
          name={'name'}
          value={navButton.name}
          placeholder={'name'}
          onChange={e => setNavButton(Object.assign({},
            navButton,
            {name: e.target.value}
          ))}
        />
      </div>

      {props.world
        && props.world.myWorlds
        && 0 < props.world.myWorlds.length
        && <InputField
              type={'select'}
              name={'location_id'}
              value={navButton.location_id ? navButton.location_id : ''}
              onChange={e => setNavButton(Object.assign({},
                navButton,
                {location_id: e.target.value}
              ))}
              options={getLocationOptions()} />}

      <AlignRight>
        <DeleteButton
          small
          onClick={deleteNavButton}
        >
          delete
        </DeleteButton>

        <Spacer
          width={`1em`}
          inline
        />

        <Button
          small
          onClick={saveNavButton}
        >
          Save
        </Button>
      </AlignRight>
    </div>
  );
}

export default connect(getStore, {
})(EditNavButton)
