// dependencies
import React, { useState } from 'react';

// components
import InputField from '../../../lib/helpers/InputField'
import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop'
//
import {
  DeleteButton,
  Button,
} from '../../../lib/styles/forms'
//
import {
  AlignRight,
  Spacer
} from '../../../lib/styles/layout'

import TooltipMedia from './TooltipMedia'

function EditTooltip(props) {

  const [tooltip, setTooltip] = useState(props.tooltip)
  // used only to store new media files
  const [media, setMedia] = useState(null)

  const saveTooltip = async () => {
    const newTT = {
      id: tooltip.id,
      world_id: props.worldID,
      name: tooltip.name,
      ctaText: tooltip.ctaText,
      ctaUrl: tooltip.ctaUrl,
      description: tooltip.description,
      tooltip_media: media && media.files[0],
    }

    const newTTs = await props.onSave(newTT)
    return newTTs
    // setTooltip(newTTs)
  }

  const deleteTooltip = (e) => {
    e.preventDefault();
    props.onDelete(tooltip.id)
  }

  const handleDrop = (files) => {
    if (0 < files.length) {
      setMedia({ files })
    } else {
      setMedia(null)
    }
  }

  if (!tooltip
    || 'undefined' === typeof tooltip.id) {
    return null;
  }

  return (
    <div className="edit-tooltip">
      <AlignRight>
        <i>
          <a href={'#close'}
            onClick={e => {
              e.preventDefault();
              props.closeTooltip()
            }}
          >
            Close this tooltip
          </a>
        </i>
      </AlignRight>

      <h4>{(0 !== tooltip.id
        && '' !== tooltip.name)
        ? tooltip.name
        : `New Tooltip`}</h4>

      <DragNDrop
        onDrop={handleDrop}
      >
        {tooltip.mediaSrc
          && <TooltipMedia
            tooltip={tooltip}
            newMedia={media}
          />}
      </DragNDrop>

      <InputField
        type={'text'}
        name={'name'}
        value={tooltip.name}
        placeholder={'name'}
        onChange={e => setTooltip(Object.assign({},
          tooltip,
          {name: e.target.value}
        ))}
      />

      <InputField
        type={'textarea'}
        name={'description'}
        value={tooltip.description}
        placeholder={'description'}
        onChange={e => setTooltip(Object.assign({},
          tooltip,
          {description: e.target.value}
        ))}
      />

      <InputField
        label={'Call To Action Button:'}
        type={'text'}
        name={'ctaText'}
        value={tooltip.ctaText || ''}
        placeholder={'Name for CTA button'}
        onChange={e => setTooltip(Object.assign({},
          tooltip,
          {ctaText: e.target.value}
        ))}
      />

      <InputField
        type={'text'}
        name={'ctaUrl'}
        value={tooltip.ctaUrl || ''}
        placeholder={'url for CTA button'}
        onChange={e => setTooltip(Object.assign({},
          tooltip,
          {ctaUrl: e.target.value}
        ))}
      />


      <AlignRight>
        <DeleteButton
          small
          onClick={deleteTooltip}
        >
          delete
        </DeleteButton>

        <Spacer
          width={`1em`}
          inline
        />

        <Button
          small
          onClick={saveTooltip}
        >
          Save
        </Button>
      </AlignRight>
    </div>
  );
}

export default EditTooltip
