import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
// import api from '../../../boxappapi/index'
import OrgAPIClient from '../../../boxappapi/org_client'
// import {getCurrentUser} from '../../../boxappapi/user_client'
import { connect } from 'react-redux'
import {storeUser, addError, addActivityIndicator} from "../../../redux/actions"
import {getStore} from "../../../redux/selectors"

import InputField from '../../../lib/helpers/InputField'

class OrgSettingsForm extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
      use_watermark: false,
      use_tripod_watermark: false,
    }
  }

  componentWillMount() {
    this.loadOrg()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.currentUser.orgs !== this.props.user.currentUser.orgs) {
      this.loadOrg()
    }
  }

  render() {
    return (<div className={`Org-Settings-Form`}>

      <form
        onSubmit={this.handleSubmit.bind(this)}
        className={'organization-settings'}>
        <InputField
          type={'checkbox'}
          name={'use_watermark'}
          id={'use_watermark'}
          value={1}
          checked={this.state.use_watermark}
          label={'Use Organization Logo as Watermark?'}
          description={'This will display a watermark with the organization logo on every render belonging to this organization.'}
          onChange={this.updateState.bind(this)} />

        <InputField
          type={'checkbox'}
          name={'use_tripod_watermark'}
          id={'use_tripod_watermark'}
          value={1}
          checked={this.state.use_tripod_watermark}
          label={'Use Organization Logo as Tripod Watermark?'}
          description={'This will display the logo at the bottom of all renders, coverign the tripod usuallu seen in 360 images.'}
          onChange={this.updateState.bind(this)} />

        <InputField
          type={'submit'}
          value={'Save Changes'}
          className={'btn primary'} />
      </form>

    </div>)
  }

  async loadOrg() {
    try {
      const org = await OrgAPIClient.getOrg()
      if (true === org.success) {
        const {settings} = org.org
        this.setState(settings)
      }
    } catch(err) {
      console.error(err)
    }
  }

  updateState(input) {
    const { target } = input;
    const newState = {}
    if ( 'checkbox' === target.type ) {
      newState[target.name] = target.checked
    } else {
      newState[target.name] = target.value
    }
    this.setState(newState)
  }

  handleSubmit(e) {
    e.preventDefault();
    this.saveOrg();
  }

  async saveOrg() {

    this.props.addActivityIndicator('Saving Settings..')

    try {
      const API = new OrgAPIClient(this.props.user.token)
      const org = await API.updateOrgSettings(this.state)

      const {settings} = org
      this.setState(settings)
    } catch(err) {
      console.error(err)
      this.props.addError(err)
    }

    this.props.addActivityIndicator('')
  }
}

export default connect(getStore, {storeUser, addError, addActivityIndicator})(OrgSettingsForm);
