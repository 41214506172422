import styled from 'styled-components'

// const defaultBackground = '/images/icons/360.png'

export const TeaserBoxWrapper = styled.div`
	position: relative;

`

export const AvatarWrapper = styled.div`
  // position: reltaive;
	position: absolute;
	z-index: 3;
	bottom: 5%;
	left: 5%;
	width: 90%;
	overflow: auto;
  padding: 0.5em;
  box-sizing: border-box;
  .user-avatar {
    position: relative;
    z-index: 99;
    white-space: nowrap;
  }
`

export const TeaserBoxBackground = styled.div`
	position: relative;
	z-index: 0;
	padding-top: 125%;
	background-image: url(${props => props.image});
	background-position: center;
	background-repeat: no-repeat;
	background-size: ${props => props.image ? 'cover' : '100'};
	background-color: #000;

  @media screen and (min-width: 600px) {
    padding-top: ${props => props.landscape ? '60%' : '125%'};
  }
`

export const TeaserBoxContent = styled.div`
	display: block;
  bottom: 5%;
  left: 5%;
  position: absolute;
  width: 90%;
  height: 90%;
  border: 1px solid #fff;
  padding: 1em;
  overflow: hidden;
  box-sizing: border-box;
  transition: all .4s ease-in-out;
  white-space: normal;
  //background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
  z-index: 1;

`

export const TeaserBoxTitle = styled.div`
	color: #fff;
  font-weight: 100;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5em;
  margin-left: 10px;
  font-weight: 300;
`

export const TeaserBoxDescription = styled.div`
  color: #fff;
  font-weight: 100;
  margin: 1em 0 0;
  // font-size: 1.5em;
  margin-left: 10px;
  font-weight: 300;
  padding-top: 0.5em;
  position: relative;
  overflow: auto;
  padding-bottom: 4em;
  height: 90%;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 35%;
    background: #fff;
  }
`

export const TeaserBoxLink = styled.a`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 2;
`