import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
import api from '../../../boxappapi/index'
import OrgAPIClient from '../../../boxappapi/org_client'
import {getCurrentUser} from '../../../boxappapi/user_client'
import { connect } from 'react-redux'
import {storeUser, addError, addActivityIndicator} from "../../../redux/actions"
import {getStore} from "../../../redux/selectors"

import InputField from '../../../lib/helpers/InputField'

class NewOrganizationForm extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
      admin: +props.user.id,
      name: '',
      title: '',

      finished: false,
    }
  }

  componentWillMount() {
    // this.loadOrg()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id) {
      this.setState({
        admin: +this.props.user.id
      })
    }
  }

  render() {

    if (this.state.finished) {
      const orgUrl = 'dev' === api.ENV
        ? `http://${this.state.name}.boxapp.localhost:3000`
        : `https://${this.state.name}.boxapp.io`

      return (<div className={'success-message'}>
        <p>Congratulations! Your new organization was created successfully. <a href={orgUrl} className={'link'}>Click here</a> to go to your new organization's home page.</p>
        <p>You can also access your organization from any page, by clicking on your user avatar on the top right corner.</p>
      </div>)
    }

    return (<div className={`New-Organization-Form`}>

      <form
        onSubmit={this.handleSubmit.bind(this)}
        className={'new-organization'}>

        <InputField
          type={'text'}
          name={'name'}
          id={'name'}
          value={this.state.name}
          label={'Organization Name'}
          placeholder={'somecoolname or my-organization'}
          description={'No spaces, use only: alphanumeric and `-`.'}
          onChange={this.updateState.bind(this)} />

        <InputField
          type={'text'}
          name={'title'}
          id={'title'}
          value={this.state.title}
          label={'Title'}
          placeholder={'My Organization'}
          onChange={this.updateState.bind(this)} />

        <InputField
          type={'submit'}
          value={'Create New Organization'}
          className={'btn primary'} />
      </form>

    </div>)
  }

  updateState(input) {
    const { target } = input;
    const newState = {}
    if ('name' === target.name) {
      newState[target.name] = target.value.replace(/([^0-9a-zA-Z-])/, '-').toLowerCase()
    } else {
      newState[target.name] = target.value
    }
    this.setState(newState)
  }

  handleSubmit(e) {
    e.preventDefault();
    this.saveOrg();
  }

  async saveOrg() {

    if ( /[^a-z-_]/.test(this.state.name) ) {
      this.props.addError({message: 'The Organization name must contain only letters, dashes (-) or undescores (_).'})
      return;
    }

    this.props.addActivityIndicator('Creating New Organization..')

    try {
      const API = new OrgAPIClient(this.props.user.token)
      const org = await API.createOrg(this.state)
      console.log(org)
      if (org.success) {
        this.props.addActivityIndicator('Saved.')
        const user = await getCurrentUser(this.props.user.token)
        this.props.storeUser(Object.assign({}, this.props.user, {
          currentUser: user, // return a temporary user w/ limited info
          authenticated: true,
          isAdmin: user.isAdmin,
        }))
        this.setState({finished: true})
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
    }

    this.props.addActivityIndicator('')
  }
}

export default connect(getStore, {storeUser, addError, addActivityIndicator})(NewOrganizationForm);
