import React, { useState, useEffect, Fragment } from 'react';

import BoxappCookie from '../../BoxappCookie'
import LoadingIndicator from '../../lib/helpers/LoadingIndicator'

import TwoFactorAuth from '../../components/TwoFactorAuth/TwoFactorAuth'
import Login from '../../components/Login/Login'
import Register from '../../components/Register/Register'
import LoginSuccess from '../../components/Login/LoginSuccess'
import WelcomeScreen from '../../components/WelcomeScreen/WelcomeScreen'

import { connect } from 'react-redux'
import {getUser} from "../../redux/selectors"
import {storeUser, addError, addActivityIndicator} from "../../redux/actions"

import {
  BackButton
} from './styles'

const LoginPage = (props) => {

  // init | pending | 2fa | loggedIn
  const [status, setStatus] = useState('init')
  const [twoFactorAuth, setTwoFactorAuth] = useState(null)
  const [logInResponse, setLogInResponse] = useState(null)

  const loggedInUser = BoxappCookie.get('boxappUser')

  useEffect(() => {
    loggedInUser && loggedInUser.id && setStatus('loggedIn')
  }, [])

  const _hanldeLoginResponse = () => {

    if (!logInResponse) return;

    const _handle2FA = () => {
      setTwoFactorAuth({
        user_id: logInResponse.user_id,
        code: '',
        token: logInResponse.token
      })
      setStatus('2fa')
    }

    const _handleLogin = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage( JSON.stringify({
          token: logInResponse.token,
          id: logInResponse.user.id
        }) )
      }

      BoxappCookie.saveUserCookie(logInResponse, (userData) => {
        props.storeUser(userData)
        props.history.push('/')
      })

    }
    //   check for 2FA first
    if (logInResponse.two_factor_auth_code_sent) {
      _handle2FA()
    } else
    // check for the user & token
    if (logInResponse.token && logInResponse.user) {
      _handleLogin()
    }

    return () => setLogInResponse(null)
  }

  const view = () => {
    switch(status) {
      case 'pending':
        return (
          <LoadingIndicator />
        )

      case 'loggedIn':
        return (
          <LoginSuccess />
        )

      case '2fa':
        return (
          <TwoFactorAuth
            user_id={twoFactorAuth.user_id}
            code={twoFactorAuth.code}
            token={twoFactorAuth.token}
            loginStarted={() => setStatus('pending')}
            onValidation={(user) => setLogInResponse({
              token: logInResponse.token,
              user: user,
            })}
          />
        )

      case 'login':
        return (
          <Login
            loginStarted={() => setStatus('pending')}
            onLogin={(resp) => setLogInResponse(resp)}
          />
        )

      case 'register':
        return (
          <Register
            goToLogin={() => setStatus('login')}
          />
        )

      case'init':
      default:
        return (
          <WelcomeScreen
            onSelection={selection => setStatus(selection)}
          />
        )
    }
  }

  useEffect(_hanldeLoginResponse, [logInResponse])

  return (
    <Fragment>
      {('login' === status
        || 'register' === status)
        && <BackButton
          onClick={() => setStatus('init')}
        />}
      {view()}
    </Fragment>
  );
}

export default connect(getUser, {
  storeUser,
  addError,
  addActivityIndicator
})(LoginPage);
