import React, { useState, useEffect, useLayoutEffect } from 'react'
import {Link} from 'react-router-dom'
import WorldAPIClient from '../../../boxappapi/world_client'
// add redux
import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  storeAllWorlds,
  storeWorldsForUser,
  addActivityIndicator,
  addError
} from "../../../redux/actions"
// components
import WorldThumbnail from '../WorldThumbnail/WorldThumbnail'
import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'
import './ListWorlds.scss'
//
import {
  NoWorldsFound,
  ListWorldsContainer,
  ListWorldsGrid,
  LoadMoreTrigger
} from './ListWorlds.components'

import useScroll from '../../../lib/hooks/useScroll'

const initalPagination = {
  current_page: 1,
  max_num_pages: 0,
  worlds_found: 0,
  worlds_showing: 0,
}

// We are going to load all worlds
// or worlds for one specific user
const ListWorlds = (props) => {

  const {
    user,
    forUser,
    linkUser,
    showEditLink
  } = props

  let loadMore = null,
      loadingWorlds = false;

  const _initialArgs = {
    author: forUser || null,
    perPage: 12,
    page: 1,
    // cat: (this.state.catSelected || []),
    status: user.isAdmin ? ['publish', 'private', 'draft'] : null
  }

  // state
  const [worlds   , setWorlds]   = useState(props.worlds || null)
  const [pagination , setPagination] = useState(initalPagination)

  const [position, pauseScroll] = useScroll()

  useEffect(() => {
    if (null === worlds
      && !loadingWorlds) {
      loadWorlds(_initialArgs)
    }

    return () => {loadingWorlds = true}
  }, [])

  useEffect(() => {
    if (worlds
      && pagination.current_page < pagination.max_num_pages
      && !loadingWorlds) {
      pauseScroll(false)
    }
  }, [worlds])

  useLayoutEffect(() => {
    if (!loadingWorlds
      && loadMore) {

      const buffer = 150
      const offset = (+loadMore.offsetTop - window.innerHeight) - buffer

      if (offset <= position) {
        loadNextPage()
        loadingWorlds = true;
        pauseScroll(true)
      }
    }

    return () => {loadingWorlds = true}

  }, [position])

  const loadWorlds = async (args) => {
    if (loadingWorlds) return

    try {
      const response = await WorldAPIClient.getWorldList(args)
      setPagination(response.pagination)
      if (!worlds || 0 === worlds.length) {
        setWorlds(response.worlds)
      } else {
        let _worlds = [...worlds, ...response.worlds]
        setWorlds(_worlds)
      }
      // call the optional callback for 'onWorldsLoaded'
      // to send worlds and pagination data to parent if
      // callback was passed in props
      if (props.onWorldsLoaded
        && 'function' === typeof props.onWorldsLoaded) {
        props.onWorldsLoaded(response.worlds, response.pagination)
      }
    } catch(err) {
      // props.addError(err)
    }
  }


  const loadNextPage = () => {
    const _newArgs = Object.assign({}, _initialArgs, {
      page: pagination.current_page + 1
    })
    loadWorlds(_newArgs)
  }

  return (
    <ListWorldsContainer>
        <ListWorldsGrid>
          {!worlds
            && <NoWorldsFound>
                Loading worlds.
              </NoWorldsFound>}

          {worlds && 0 < worlds.length
            && worlds.map((world, i) => (
              <WorldThumbnail
                key ={world.ID}
                world={world}
                user={user}
                link={linkUser}
                showEditLink={showEditLink}
              />
            ))}

          {worlds && 0 === worlds.length
            && <NoWorldsFound>
                Hmm..<br />
                There seem to be no Worlds here.
                <br /><br />
                <Link
                  to={'/new-world'}
                  className={'link'}
                >
                  Create one.
                </Link>
              </NoWorldsFound>}
        </ListWorldsGrid>

        {pagination
          && pagination.current_page < pagination.max_num_pages
          && <LoadMoreTrigger
            ref={ref => loadMore = ref}
          >
            <LoadingIndicator
              padding={'3em'}
            />
          </LoadMoreTrigger>}
    </ListWorldsContainer>
  )
}

export default connect(getStore, {
  storeAllWorlds,
  storeWorldsForUser,
  addActivityIndicator,
  addError
})(ListWorlds);
