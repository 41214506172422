import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import InputField from '../../../lib/helpers/InputField'

import {
	Row,
	Column
} from '../../../lib/styles/layout'

export const FilePreviewContainer = styled.div`
	position: relative;
	display: block;
	background-color: #fff;
	padding: 0.5em;
	border: 1px solid #ccc;
	margin-bottom: 0.25em;
`

export const FilePreviewTtitle = styled.div`
	position: relative;
	display: block;

`

export const FilePreviewInstructions = styled.div`
	display: block;
	text-align: center;
	font-size: 80%;
	font-style: italic;
	margin-top: 1em;
`

export const EditObjectWrapper = styled.div`
	display: block;
	padding: 1em;
	margin-bottom: 2em;
	border-bottom: 1px solid #ccc;
	& > h4 {
		margin-top: 0;
	}
`

export const EditObjectButtons = styled.div`
	display: block;
	text-align: right;
`


export const FilePreview = (props) => {
	const {
		files
	} = props;

	const getFileName = (file) => {
		return file && file.name
			? file.name
			: file && file.uri
				? basename(file.uri)
				: ''
	}

	const basename = (path) => {
	   return path.split('/').reverse()[0];
	}

	return (<FilePreviewContainer>
		{files && 0 < files.length
  		? files.map((file, idx) => {
  			return (<FilePreviewTtitle key={idx}>
					{getFileName(file)}
				</FilePreviewTtitle>)
  		})
  		: ''}
  	<FilePreviewInstructions>
  		To replace files, drag and drop new files here.
  	</FilePreviewInstructions>
	</FilePreviewContainer>)
}


export const XYZFields = (props) => {
	const {
		title,
		value
	} = props

	const [x, setX] = useState((value && value[0]) || '0')
	const [y, setY] = useState((value && value[1]) || '0')
	const [z, setZ] = useState((value && value[2]) || '0')

	useEffect(() => {
		const coords = [x, y, z]
		props.onChange(coords.join(','))

		return () => {}
	}, [x,y,z])

	return (<>
		<label>
			{(title && title) || ""}
			<Row>
				<Column size={3} gutter={2}>
					<InputField
						type={'number'}
						value={x}
						onChange={e => setX(e.target.value)} />
				</Column>
				<Column size={3} gutter={2}>
					<InputField
						type={'number'}
						value={y}
						onChange={e => setY(e.target.value)} />
				</Column>
				<Column size={3} gutter={2}>
					<InputField
						type={'number'}
						value={z}
						onChange={e => setZ(e.target.value)} />
				</Column>
			</Row>
		</label>
	</>)
}