import React from 'react';
import {
  MenuSectionWrapper,
  MenuSectionTitle,
  MenuSectionInner
} from './Menus.components'

/*
NOT BEING USED YET
 */
function MenuSection(props) {

  return (<MenuSectionWrapper>
    <MenuSectionInner>
      {props.title
        && <MenuSectionTitle>
          <h5>
            {props.title}
          </h5>
        </MenuSectionTitle>}
      {props.children}
    </MenuSectionInner>
  </MenuSectionWrapper>);
}

export default MenuSection
