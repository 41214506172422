import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
import api from '../../../boxappapi/index'
import OrgAPIClient from '../../../boxappapi/org_client'
import {getCurrentUser} from '../../../boxappapi/user_client'

import { connect } from 'react-redux'
import {storeUser, addError, addActivityIndicator} from "../../../redux/actions"
import {getStore} from "../../../redux/selectors"

import InputField from '../../../lib/helpers/InputField'

import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop'

import'./EditOrganizationPage.css'

class EditOrganizationForm extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
      org_logo: '',
      name: '',
      tagline: '',
    }
  }

  componentWillMount() {
    this.loadOrg()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.currentUser.orgs !== this.props.user.currentUser.orgs) {
      this.loadOrg()
    }
  }

  render() {
    const orgLogo = this.state.org_logo
      ? this.state.org_logo
      : '/images/logo/logo.png'

    return (<div className={`Edit-Organization-Form`}>

      <form
        onSubmit={this.handleSubmit.bind(this)}
        className={'edit-organization'}>

        <DragNDrop
          onDrop={files => {
            this.setState({
              org_logo: files[0]
            }, this.saveOrg.bind(this))
          }}>
          <div className={'org-logo'}>
            <div className={'org-logo-figure'}
              style={{
                backgroundImage: `url(${orgLogo})`
              }} />
          </div>
        </DragNDrop>

        <InputField
          type={'text'}
          name={'name'}
          id={'name'}
          value={this.state.name}
          label={'Organization Name'}
          onChange={this.updateState.bind(this)} />

        <InputField
          type={'text'}
          name={'tagline'}
          id={'tagline'}
          value={this.state.tagline}
          label={'Tagline'}
          onChange={this.updateState.bind(this)} />

        <InputField
          type={'submit'}
          value={'Save Changes'}
          className={'btn primary'} />
      </form>

    </div>)
  }

  loadOrg() {
    const currOrg = api.getOrgbyName(api.org, this.props.user.currentUser.orgs)

    this.setState({
      org_logo: currOrg.org_logo && currOrg.org_logo.uri,
      name: currOrg.blogname,
      tagline: currOrg.tagline,
    })
  }

  updateState(input) {
    const { target } = input;
    const newState = {}
    if ('file' === target.type
      && 0 < target.files.length) {
      newState[target.name] = target.files[0]
    } else {
      newState[target.name] = target.value
    }
    this.setState(newState)
  }

  handleSubmit(e) {
    e.preventDefault();
    this.saveOrg();
  }

  async saveOrg() {

    this.props.addActivityIndicator('Saving Organization..')

    try {
      const API = new OrgAPIClient(this.props.user.token)
      const org = await API.updateOrg(this.state)
      if (org.success) {
        this.props.addActivityIndicator('Saved.')
        const user = await getCurrentUser(this.props.user.token)
        this.props.storeUser(Object.assign({}, this.props.user, {
          currentUser: user, // return a temporary user w/ limited info
          authenticated: true,
          isAdmin: user.isAdmin,
        }))
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
    }

    this.props.addActivityIndicator('')
  }
}

export default connect(getStore, {storeUser, addError, addActivityIndicator})(EditOrganizationForm);
