import React, { Component } from 'react';
import { Link } from "react-router-dom";
import api from '../../../boxappapi/index'
import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"

import AdminPage from "../../../components/AdminPage/AdminPage"
import {PageH1} from "../../../lib/styles/typography"
import {MaxWidthContent} from "../../../lib/styles/layout"
import NewOrganizationForm from './NewOrganizationForm'
import './NewOrganizationPage.scss'

import Header from '../../../components/Header'

class NewOrganizationPage extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
      organization: null,
    }
  }

  render() {

    return (<AdminPage>
      <Header
        left
        right
      />
      <div className={`New-Organization`}>
        <PageH1>New Organization</PageH1>
        <MaxWidthContent
          width={`800px`}
          padding={`0 2em`}
        >
          <p>Organizations are BoxApp accounts that offer more business-oriented features, like branding or inviting and managing users. Take a look at <Link to={'/page/understanding-boxapp-organizations'} className={'link'}>Understanding BoxApp Organizations</Link> to learn more.</p>
          <NewOrganizationForm />
        </MaxWidthContent>
      </div>
    </AdminPage>)
  }

  loadOrganization() {
    const organization = this.props.user.currentUser
      ? api.getOrgbyName(api.org, this.props.user.currentUser.orgs)
      : null

    this.setState({
      organization: organization
    })
  }
}

export default connect(getStore, {})(NewOrganizationPage);
