import styled from 'styled-components'

import {Colors} from '../../../lib/styles/styles'

export const NoWorldsFound = styled.div`
	text-align: center;
	padding: 25% 5%;
  color: ${Colors.gray};
  .link {
    color: ${Colors.gray};
    border-color: ${Colors.gray};
  }
`

export const ListWorldsContainer = styled.div`
	display: block;


  &.inline {
    white-space: nowrap;
    overflow: auto;

    .world {
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

export const ListWorldsGrid = styled.div`
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .world {
    border-bottom: 1em solid #f4f4f4;
  }

  @media screen and (min-width: 600px) {
    .world {
      width: 50%;
      float: left;
      box-sizing: border-box;
      border-left: 0.5em solid #f4f4f4;
      border-right: 0.5em solid #f4f4f4;
    }
  }


  @media screen and (min-width: 1000px) {
    .world {
      width: 33.333%;
    }
  }


  @media screen and (min-width: 1300px) {
    .world {
      width: 25%;
    }
  }
`

export const LoadMoreTrigger = styled.div`

`