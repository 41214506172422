/**
 * Handle All App Routes
 * and displays the app as well as doc head
 *
 * @package boxApp
 */
import React, {Fragment} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
//
import DocumentHead from './lib/helpers/DocumentHead'
// import Footer from './components/F`  ooter/Footer'
import App from './App';
// Worlds
import Home from './pages/Home';
import Search from './pages/Search';
// import MyWorlds from './pages/User/MyWorlds'
import NewWorld from './pages/World/NewWorld';
import EditWorldPage from './pages/World/EditWorld'
// Login/Registration/User
import LoginPage from './pages/Login';
import Logout from './pages/admin/Logout';
import Register from './pages/Login/Register';
import RegisterSuccess from './components/forms/RegistrationForm/RegistrationFormSuccess';
import UserPage from './pages/User/UserPage'
import UserProfile from './pages/User/UserProfile';
import UserFollowers from './pages/User/UserFollowers';
// Organizations
import EditOrganizationPage from './pages/Organization/EditOrganization/EditOrganizationPage'
import ManageUsersPage from './pages/Organization/ManageUsers/ManageUsersPage'
import NewUser from './pages/Organization/NewUser/NewUser'
import OrgSettingsPage from './pages/Organization/Settings/OrgSettingsPage'
import NewOrganizationPage from './pages/Organization/NewOrganization/NewOrganizationPage'
//
import {Capitalize} from './lib/helpers'
// get the app version
import {version} from '../package.json';
// initialize google analytics
ReactGA.initialize('UA-36643420-7');

const Router = (props) => {

  // this is the actual router
  const routerInner = () => {
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/logout" component={Logout} />
        <Route path="/register" component={Register} />
        <Route path="/register-success" component={RegisterSuccess} />
        <Route path="/user-profile/:user_id" component={UserProfile} />
        <Route path="/user-followers" component={UserFollowers} />

        <Route path="/new-world" component={NewWorld} />
        <Route path="/edit-world/:worldId" component={EditWorldPage} />

        <Route path="/org/edit-organization" component={EditOrganizationPage} />
        <Route path="/org/manage-users" component={ManageUsersPage} />
        <Route path="/org/new-user" component={NewUser} />
        <Route path="/org/settings" component={OrgSettingsPage} />
        <Route path="/org/new" component={NewOrganizationPage} />

        <Route
          path="/user/:id"
          render={props => (<UserPage {...props} />)}
        />

        <Route
          path="/search/:keyword?"
          render={props => (<Search {...props} />)}
        />

        <Route
          path="/"
          render={() => (<Home />)}
        />
      </Switch>
    );
  }

  const renderApp = props => {
    const {location} = props
    // run google analytics code
    ReactGA.pageview(location.pathname + location.search)

    const _isEditMode = isEditMode(location)
    const _isLoginPage = isLoginPage(location)
    const _isRegisterPage = isRegisterPage(location)

    const _pageClass = getPageClass(location)
    const _pageTitle = getPageTitle(location)

    return (<Fragment>
      <DocumentHead
        {...props}
        page={_pageClass}
        title={_pageTitle}
      />

      <App
        {...props}
        version={version}
        isEditMode={_isEditMode}
        isLoginPage={_isLoginPage}
        isRegisterPage={_isRegisterPage}
        page={_pageClass}
      >
        {routerInner()}
      </App>
    </Fragment>)
  }

  const getPageClass = (location) => {
    const page = location
      && location.pathname
      && location.pathname.match(/^\/([^/]*)\/?/i)
    return (page && null === page)
      ? 'home'
      : ('' === page[1])
        ? 'home'
        : page[1]
  }

  const getPageTitle = (location) => {
    var regex = /-|_/gi;
    const title = getPageClass(location).replace(regex, ' ').split(' ')
    return title.map(t => Capitalize(t)).join(' ')
  }

  const isEditMode = (location) => {
    return (null !== location.pathname.match(/^(\/edit-world)/i))
  }

  const isLoginPage = (location) => {
    return (null !== location.pathname.match(/^(\/login)/i))
  }

  const isRegisterPage = (location) => {
    return (null !== location.pathname.match(/^(\/register)$/i))
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
        render={renderApp} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
