import React, { Component } from 'react';
import { connect } from 'react-redux'
import {removeError} from "../../redux/actions"

class HandleError extends Component {

  constructor(props) {
    super(props)

    this.state = {
      message: '',
      resolution: {
        message: '',
        cta: '',
      }
    }
  }

  render() {
    if (!this.props.error) {
      return null;
    }
    return (
      <div className={`error-indicator ${this.props.error.type}`}>
        <button 
          className={'close'}
          onClick={this.closeError.bind(this)}>X</button>
        <p>{this.props.error.message}</p>
      </div>
    )
  }

  closeError() {
    this.props.removeError(this.props.errorKey)
  }
}

export default connect(null, {removeError})(HandleError);