import React, { useState } from 'react'

import { connect } from 'react-redux'
import {
  addError,
} from "../../../redux/actions"

import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop'

import {
	FilePreview,
} from './EditObject.components'

const convertObjectToArray = (object) => {
	let _array = []
	for ( var i in object) {
		if (object.hasOwnProperty(i)) {
			_array = [..._array, object[i]]
		}
	}
	return _array
}

const validateFiles = (files) => {
	// check for type in path
	const fileReg = new RegExp(/\.obj$/, 'i');
	let checkOBJ = false
	for (var i = 0; i < files.length; i++) {
		const file = files[i]
		if (fileReg.test(file.path)) {
			checkOBJ = true;
		}
	}

	return checkOBJ
}

function ObjectFiles(props) {

	const [files, setFiles] = useState( convertObjectToArray(props.currentFiles) )

	const saveFile = (files) => {
		if (0 === files.length) {
			setFiles([])
			return;
		}

		if (validateFiles(files)) {
			setFiles(files)
			// OK to proceed
			props.saveFiles(files)
		} else {
			props.addError({
				message: `You must upload at least one .OBJ file for objects to work.`
			})
		}

	}

	return (<DragNDrop onDrop={saveFile} message={`Drop Files here. Or click to select.`}>
  	{files && 0 < files.length
  		? <FilePreview files={files} />
  		: ''}
  </DragNDrop>)
}

export default connect(null, {
  addError,
})(ObjectFiles);
