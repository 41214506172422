import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Colors = {
	white: '#FFFFFF',
	offWhite: '#f4f4f4',
	lightGray: '#efefef',
	gray: '#ccc',
	darkGray: '#444',
	black: '#222',
	transparent: 'rgba(255, 255, 255, 0)',
	darkBlue: 'rgb(28, 48, 70)',
	blue: '#3863e4',
	purple: '#874ca0',
	green: '#56c5bb',
	red: '#da5320',
	brightBlue: '#007AFF',
	opaqueWhite: 'rgba(255, 255, 255, 0.95)',

}

export const Icons = {
	plus: '/images/icons/cross.png',
	newBtn: '/images/icons/new-btn.png',
}

export const Gradient = styled.div`
	background: linear-gradient(
		${props => props.start || Colors.transparent},
		${props => props.end || Colors.darkBlue}
	);
	height: ${props => props.height || '50%'};
	width: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	display: block;
	z-index: 0;
`

export const NewWorldButtonWrapper = styled.div`
  z-index: 99999999;
	position: fixed;
  display: block;
  bottom: 1.25em;
  right: 1.25em;
  margin: 0;
  padding: 0.125em;
  width: 3em;
  height: 3em;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${Colors.blue};
  background-color: ${Colors.blue};
  box-shadow: 5px 5px 20px 1px ${Colors.darkGray};

  background-image: url(${Icons.newBtn});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
`

export const NewWorldButton = styled(Link)`
	position: absolute;
	top: 0;
	left: 0;
  display: block;
  width: 100%;
  height: 100%;
`