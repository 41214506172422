import React, { Component } from 'react';
import { Link } from "react-router-dom";

import api from '../../../boxappapi/index'
import {getUsers} from '../../../boxappapi/user_client'

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"

import {PageH1} from "../../../lib/styles/typography"
import {PageContent} from "../../../lib/styles/layout"
import TableHeader from "../../../lib/helpers/TableHeader"
import TableRow from "../../../lib/helpers/TableRow"
import AdminPage from "../../../components/AdminPage/AdminPage"
import OrgSubNav from '../SubNav/OrgSubNav'
import BottomNav from "../../../components/Navigation/BottomNav"
import './ManageUsersPage.css'

class ManageUsersPage extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
      users: []
    }
  }

  componentDidMount() {
    this.loadUsers()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.currentOrg !== this.props.user.currentOrg) {
      this.loadUsers();
    }
  }

  render() {

    if (!this.props.user.currentUser) {
      return null;
    }
    const _org = api.getOrgbyName(api.org, this.props.user.currentUser.orgs)
    const orgName = _org ? _org.blogname : ''
    return (<AdminPage isAdmin={1} isOrg={1}>
      <PageContent>
      <div className={`Manage-Users`}>
        <OrgSubNav location={this.props.location} />

        <PageH1>{orgName}</PageH1>

        <div className={'new-user-btn'}>
          <Link className={'new-user btn primary small'} to={'/org/new-user'}>
            Add New User
          </Link>
        </div>

        <div className={'pwp-clear'} />

        <TableHeader>
          <div>Avatar</div>
          <div>User</div>
          <div>Username</div>
          <div>Is Admin</div>
          <div>Edit</div>
        </TableHeader>
        <div className={'table'}>
          {this.state.users.map((user, i) => {
            return (<TableRow
              key={i}
              className={'edit-user'}
              link={`/user-profile/${user.id}`}>
              <div className={'pwp-align-center'}>
                <img
                  className={'pwp-inline-block'}
                  src={user.avatar_urls[48]}
                  alt={user.name} />
              </div>
              <div className={'pwp-align-center'}>
                {user.name}
              </div>
              <div className={'pwp-align-center'}>
                {user.username}
              </div>
              <div className={'pwp-align-center'}>
                {(user.isAdmin) ? 'Yes' : 'No'}
              </div>
              <div className={'pwp-align-center'}>
                <Link className={'link'} to={`/user-profile/${user.id}`}>
                  edit
                </Link>
              </div>
            </TableRow>)
          })}
        </div>
      </div>
      </PageContent>
      <BottomNav />
    </AdminPage>)
  }

  async loadUsers() {
    try {
      const response = await getUsers()
      const {users} = response;
      this.setState({users})
    } catch(err) {
      console.error(err)

    }
  }
}

export default connect(getStore, {})(ManageUsersPage);
