import React, { Component } from 'react';

class TableHeader extends Component {

  render() {
  	const count = this.props.children.length

  	const children = React.Children.map(this.props.children, child => {
  		return React.cloneElement(child, {
	    	className: child.props.className + ' table-header-cell pwp-align-center col'+count,
	    });
	  });

    return (
      <div className={`table-header pwp-inline cols${count}`}>
        {children}
      </div>
    )
  }
}

export default TableHeader