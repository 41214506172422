import React, {Fragment} from 'react'
import ListWorlds from '../../components/World/ListWorlds'
import BottomNav from '../../components/Navigation/BottomNav'
import Header from '../../components/Header'
import api from '../../boxappapi'
import { connect } from 'react-redux'
import {getStore} from "../../redux/selectors"
import {Logo} from './styles'

const Title = (props) => {
	if (api.org
		&& '' !== api.org) {
		const __org = api.getOrgbyName(
			api.org,
			props.user.currentUser.orgs
		)
		return (<Fragment>
			{__org.blogname}
		</Fragment>)
	}

	return <Logo />
}

const Home = (props) => {
	return (<Fragment>
			<Header
				right={true}
				title={() => (<Title {...props} />)}
			/>
			<ListWorlds />
			<BottomNav />
		</Fragment>)
}

export default connect(getStore, {})(Home);