import { useState, useLayoutEffect } from 'react';



function useScroll() {

	const [position, setPosition] = useState(0)
	const [pause, setPause] = useState(false)

	useLayoutEffect(() => {

		const handleScroll = (e) => {
			if (pause) {
				return null;
			}
			const Doc = e.target.documentElement
			setPosition(Doc.scrollTop)
		}

		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	})

	const pauseScroll = (bool) => setPause(bool)

  return [position, pauseScroll]
}

export default useScroll
