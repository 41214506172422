import styled from 'styled-components'

export const BackButton = styled.button`
	position: fixed;
	top: 1em;
	left: 1em;
	z-index: 999;
	background-image: url(/images/icons/chevronLeft@3x.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 2.5em;
	height: 2.5em;
	background-color: transparent;
	border: 0;
`