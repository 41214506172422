import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../boxappapi/index'
import TeaserBox from '../../../components/TeaserBox/TeaserBox'
import './WorldThumbnail.scss'

class WorldThumbnail extends Component {

  constructor(props) {
    super(props)

    this.state ={
      hover: false,
      showDescription: false,
    }
  }

  render() {
    const thumb = this.getThumbnail(this.props.world)
    const url   = api.getVRURL(this.props.world.ID)

    return (<div className={'world'}>
      <TeaserBox
        link={url}
        background={thumb}
        description={this.props.world.post_content}
        title={this.props.world.post_title}
        user={this.props.world.user} />

      {this.maybeDisplayEditLink()}
    </div>);
  }

  maybeDisplayEditLink() {


    if (api.org && '' !== api.org
      && this.props.user.currentUser.isAdmin) {
      return this.editLink()
    }

    if (this.props.showEditLink
      && +this.props.user.id === +this.props.world.user.id) {
      return this.editLink()
    }

    return null
  }

  editLink() {
    return (<Link
      className={`link edit-world`}
      to={`/edit-world/${this.props.world.ID}`}
    >
      <img
        src={'/images/icons/pencilAlt@2x.png'}
        alt={'edit pancil'}
      />
    </Link>)
  }

  toggleDescription() {
    this.setState({showDescription: !this.state.showDescription})
  }

  getThumbnail( world ) {
    const thumb = world
      && world.pano_media
      && world.pano_media.large;

    if ( 'undefined' === typeof thumb ) {
      return ''
    }

    return thumb[0]
  }
}

export default WorldThumbnail;