import React from 'react';

import MultipleWorldsForm from './MultipleWorldsForm'
import AdminPage from '../../../components/AdminPage/AdminPage'

import Header from '../../../components/Header'

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {setWorldToEdit, addActivityIndicator, addError} from "../../../redux/actions"
import {PageH1} from "../../../lib/styles/typography"
import {MaxWidthContent} from "../../../lib/styles/layout"

import './NewWorld.scss'

function NewWorld(props) {

  return (<AdminPage>

    <Header
      history={props.history}
      left={true}
      right={true}
    />

    <div className="New-World">
      <PageH1>
        Create worlds
      </PageH1>

      <MaxWidthContent
        width={`800px`}
        padding={`0 2em`}
        height={'auto'}
      >
        <div>
          <p>Upload 360 photos or videos. Worlds will be created automatically.</p>
        </div>

        <MultipleWorldsForm />
      </MaxWidthContent>
    </div>
  </AdminPage>
  )
}

export default connect(getStore, {
  setWorldToEdit,
  addActivityIndicator,
  addError,
})(NewWorld);
