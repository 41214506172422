const initialState = {
  id: 0,
  token: '',
  currentUser: null,
  authenticated: false,
  isAdmin: false,
  // cached users
  otherUsers: [],
  currentOrg: {
    id: 1,
    name: '',
    title: '',
  },
}

const updateUser = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_USER": {

		  const { user } = action.payload
      return Object.assign({}, state, user);
    }

    case "STORE_OTHER_USERS": {

      const { user } = action.payload

      let match = false;
      for (var i = state.otherUsers.length - 1; i >= 0; i--) {
        if (user.id === state.otherUsers[i].id) {
          state.otherUsers[i] = user
          match = true
        }
      }

      const newState = {}

      if (!match) {

        newState.otherUsers = [...state.otherUsers, user]
      } else {
        newState.otherUsers = state.otherUsers
      }

      return Object.assign({}, state, newState);
    }

    case "SET_CURRENT_ORG": {
      const {currentOrg} = action.payload
      console.log(Object.assign({}, state, {currentOrg}))
      return Object.assign({}, state, {currentOrg});
    }

    case "RESET_USER": {
      return Object.assign({}, state, initialState);
    }

    default: {
      return state;
    }
  }
};

export default updateUser;
