import React, { useState, useEffect } from 'react';

import LightsAPIClient from '../../../boxappapi/lights_client';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'
import EditAmbientLight from './EditAmbientLight'

const EditLights = (props) => {

	const [lights, setLights] = useState( null )

	useEffect( () => {
		getLights()
	}, [] )

	const getLights = async () => {
		try {
			const _lights = await LightsAPIClient.getLights(
				props.worldID
			)

			setLights( _lights )
		} catch( err ) {
			console.error(err)
			props.addError( err )
		}
	}

	const updateLights = (type, newLights) => {
		newLights['world_id'] = props.worldID
		saveLight(type, newLights)
	}

	const saveLight = async ( type, args ) => {
		props.addActivityIndicator('Saving lights..')
		try {
			const client = new LightsAPIClient(props.user.token)
			const response = await client.updateLights( type, args )
			if (true === response.success) {
				props.addActivityIndicator('')
				props.updatePreview()
			} else {
				props.addActivityIndicator('hmm.. Something`s wrong. Try that again.')
			}

		} catch(err) {
			console.error(err)
			props.addError(err)
			props.addActivityIndicator('')
		}
	}

	if (!lights) {
		return <LoadingIndicator />
	}

	return (<div>
		{lights
			&& lights.ambient
			&& <EditAmbientLight
					light={lights.ambient}
					onChange={updateLights}
				/>}

    <p><i>Lights affect 3D objects only. Not the World itself.</i></p>
	</div>)
}


export default connect(getStore, {
  addActivityIndicator,
  addError,
})(EditLights);
