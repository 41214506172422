import React, { useState, useEffect, Fragment } from 'react';
// import { Link } from "react-router-dom";
// api
// import api from '../../../boxappapi'
import {getUsers} from '../../../boxappapi/user_client'
// redux
import { connect } from 'react-redux'
import {addError, addActivityIndicator} from "../../../redux/actions"
import {getStore} from "../../../redux/selectors"
// components
import UserAvatar from '../../../components/UserAvatar/UserAvatar'
import Header from '../../../components/Header'
// import UserNav from '../../../components/Header/UserNav'
// import BottomNav from '../../../components/Navigation/BottomNav'
// import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'

import {
  H3,
  // CenteredText,
  // Spacer,
} from '../../../lib/styles/typography'

import {
  PageContent
} from '../../../lib/styles/layout'

import {
  FollowerCard
} from './styles'


function UserFollowers(props) {

  const {
    followers,
    title
  } = props.location.state

  let _loadingUsers = false

  const [users, setUsers] = useState([])

  useEffect(() => {
    if (!followers) return;

    const _loadUsers = async () => {
      const _followers = followers
      try {
        const response = await getUsers({
          include: _followers.join(',')
        })
        response
          && response.success
          && setUsers(response.users)
      } catch(err) {
        console.error(err)
      }
    }

    !_loadingUsers && _loadUsers()

    return () => _loadingUsers = true
  }, [])

  return (<Fragment>
    <Header
      history={props.history}
      left
      title={title || ''}
    />

    <PageContent
      height={'auto'}
    >

    {users.map((user, idx) => (
      <FollowerCard
        key={idx}
      >
        <UserAvatar
          link={true}
          size={48}
          userObj={user}
        />
        <H3>
          {user.username}
        </H3>
      </FollowerCard>
    ))}

    </PageContent>
  </Fragment>)
}

export default connect(getStore, {addError, addActivityIndicator})(UserFollowers);
