import React, { useState } from 'react';
// redux
import { connect } from 'react-redux'
import {addError, addActivityIndicator} from "../../../redux/actions"
// api
import NavButtonAPIClient from '../../../boxappapi/navButton_client'
// componenets
// import EditNavButton from './EditNavButton'
import './NavButtons.css'

import {P} from '../../../lib/styles/typography'

// import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'
import ListNavButtons from './ListNavButtons'
import {
  EditTTList,
  TTListEmpty
} from '../Tooltips/styles'

function EditNavButtons(props) {

  const {
    worldID,
    user,
    updatePreview,
  } = props

  const [navButtons, setNavButtons] = useState(props.navButtons)
  // const [updating, setUpdating] = useState(false)

  const saveNavButton = async (newNB) => {

    // setUpdating(true)
    props.addActivityIndicator('saving nav button..')
    try {
      const NBApi = new NavButtonAPIClient(user.token)
      const response = await NBApi.updateNavButton(newNB)

      if (response.data
        && response.data.id) {
        let __newNB = response.data.navButton
        if (0 === newNB.id) {
          // we just updated a brand new navButton
          // tell the parent container about it.
          __newNB = Object.assign({}, newNB, response.data.navButton)
        }
        reloadNavButtons()
        props.addActivityIndicator('')
        return __newNB;
      }
    } catch (err) {
      console.error(err)
      props.addError(err)
    }
    props.addActivityIndicator('')
  }

  const reloadNavButtons = async () => {
    try {
      const response = await NavButtonAPIClient.getNavButtons(worldID)

      if (response.data
        && response.data.success) {
        updatePreview('navButtons')
        setNavButtons(response.data.nav_buttons)
        // setUpdating(false)
      }
    } catch (err) {
      console.error(err)
      addError(err)
    }
  }

  const deleteNavButton = async (navButtonID) => {
    // setUpdating(true)
    props.addActivityIndicator('Removing navButton..')
    const args = {
      id: navButtonID,
      world_id: worldID,
    }
    try {
      const TTApi = new NavButtonAPIClient(user.token)
      const response = await TTApi.deleteNavButton(args)

      if (response.data
        && response.data.success) {
        reloadNavButtons()
      }
    } catch (err) {
      console.error(err)
      props.addError(err)
    }
    props.addActivityIndicator('')
  }
console.log(navButtons)
  return (
    <EditTTList>
      {0 === navButtons.length
        && <TTListEmpty>
          <P>No nav buttons have been created yet.</P>
        </TTListEmpty>}

      {<ListNavButtons
        navButtons={navButtons}
        worldID={worldID}
        user={user}
        saveNavButton={saveNavButton}
        deleteNavButton={deleteNavButton}
      />}
    </EditTTList>
  );
}

export default connect(null, {addError, addActivityIndicator})(EditNavButtons);
