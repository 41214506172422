import React from 'react';

import {
	BreadCrumbWrapper,
	BreadCrumbTitle,
	// BreadCrumbArrow
} from './SlidingTreeNav.components'

const BreadCrumb = (props) => {

	const {
		title,
	} = props;

	return (<BreadCrumbWrapper>
		<BreadCrumbTitle>
			{`/${title}`}
		</BreadCrumbTitle>
	</BreadCrumbWrapper>)
}


export default BreadCrumb
