import React, { Component } from 'react';
import { Link } from "react-router-dom";

import api from '../../../boxappapi/index'
import WorldAPIClient from '../../../boxappapi/world_client'

import InputField from '../../../lib/helpers/InputField'
import MediaPreview from '../../../lib/helpers/MediaPreview'

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addError
} from "../../../redux/actions"
import './WorldUploader.scss'

// import {
//   Colors
// } from '../../../lib/styles/styles'

import {
  H6
} from '../../../lib/styles/typography'

import {
  Button
} from '../../../lib/styles/forms'

const Uploading = (props) => {
  return (<p>
      Uploading {props.name}
    </p>)
}

const UploadFailed = (props) => {
  return (<p>
      Failed to upload {props.name}
    </p>)
}

class WorldUploader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pano: props.pano,
      // the form
      worldId: 0,
      world: null,
      isVideo: false,
      status: api.isOrg() ? "draft" : "publish",
      panoName: props.pano && this.getReadableName(props.pano.name || ''),
      // upload process
      uploadSuccessful: false,
      uploading: false,
      uploadFailed: false,
    }

    this.interval = null
    this.keyUp = false
  }

  componentWillMount() {
    // if (1 !== this.props.user.currentOrg.id) {
    //   this.setState({status: 'draft'})
    // }
    this.createWorldFromFile()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pano !== this.props.pano) {
      this.setState({
        pano: this.props.pano,
        panoName: this.props.pano && this.getReadableName(this.props.pano.name || ''),
        worldId: 0,

        uploadSuccessful: false,
        uploading: false,
        uploadFailed: false,
      },
      this.createWorldFromFile.bind(this))
    }
  }

  render() {
    const loadingClass = this.state.uploading
      ? 'uploading'
      : this.state.uploadSuccessful
        ? 'done'
        : 'failed'

    return (
      <div className={`world-uploader-indicator ${loadingClass}`}>
        <div className="world-uploader">
          {0 === this.state.worldId
            && this.state.uploading
            && <Uploading name={this.state.panoName} />}

          {this.state.uploadFailed
            && <UploadFailed name={this.state.panoName} />}

          {this.state.uploadSuccessful
            && <div>
              <H6 className={'header'}>World created successfully!</H6>
              {this.state.isVideo
                && this.state.world
                && this.state.world.pano_video
                && <MediaPreview
                  media={{
                    panoVideo: this.state.world.pano_video
                  }}
                />}

              {!this.state.isVideo
                && this.state.world
                && this.state.world.pano_media
                && <MediaPreview
                  media={{
                    panoMedia: {
                      uri: this.state.world.pano_media.large[0],
                    }
                  }}
                />}

              <div className={'uploader-links'}>
                <Link
                  to={`/edit-world/${this.state.worldId}`}
                  className={'link edit'}
                  target={'_blank'}>
                  Edit World
                </Link>
                <a
                  href={`${api.getVRURL(this.state.worldId)}`}
                  className={'link view'}
                  target={'_blank'}>
                  View World
                </a>
              </div>

              <InputField
                label={'Update Name:'}
                type={'text'}
                value={this.state.panoName}
                onChange={e => this.setState({panoName: e.target.value})} />
              {api.isOrg()
                && <InputField
                  label={'World Status:'}
                  type={'select'}
                  value={this.state.status}
                  options={[
                    <option key={'draft'} value={'draft'}>Draft</option>,
                    <option key={'publish'} value={'publish'}>Publish</option>,
                    <option key={'private'} value={'private'}>Private</option>
                  ]}
                  onChange={e => this.setState({status: e.target.value})} />}
              <div className={'uploader-links'}>
                <Button
                  small
                  onClick={e => {
                    e.preventDefault();
                    this.updatePano()
                  }}
                >
                  Update World
                </Button>
              </div>
            </div>}
        </div>

          <div
            className={`indicator`} />
      </div>
    );
  }

  checkWhetherToSave() {
    if (this.keyUp) return

    this.keyUp = true

    this.interval = setTimeout(this.updatePano.bind(this), 3000)
  }

  async updatePano() {
    this.setState({uploading: true})

    clearInterval(this.interval)

    try {
      const data = {
        name: this.state.panoName,
        status: this.state.status
      }

      const API = new WorldAPIClient(this.props.user.token);

      const response = await API.updateWorld(this.state.worldId, data)

      if (response.data
        && true === response.data.success) {
        this.setState({
          uploadSuccessful: true,
          worldId: response.data.worldId,
          uploading: false
        })
      }
    } catch(err) {
      console.error(err)
      this.props.addError(err)
      this.setState({
        uploadFailed: true,
        uploading: false
      })
    }

    this.keyUp = false
  }

  authenticateUser() {
    if (!this.props.user.authenticated) {
      this.props.addError({
        status: 403,
        message: 'You are not logged in',
      })
      this.setState({
        uploadFailed: true,
        uploading: false
      })
      return false
    }
    return true
  }

  createWorldFromFile() {
    const pano = this.state.pano

    if ('File' !== pano.constructor.name) {
      return
    }

    const type = null !== pano.type.match(/^image/)
      ? 'image'
      : 'video'

    const data = {
      name: this.state.panoName,
    }

    if ('image' === type) {
      data.pano_media = pano
    } else {
      this.setState({isVideo: true})
      data.pano_video = pano
    }

    this.savePano(data)

    this.setState({uploading: true})
  }

  async savePano(data) {

    if (!this.authenticateUser()) {
      return
    }

    data = data || {}

    if (this.props.user
      && this.props.user.token
      && (0 < this.props.user.id)) {

      try {
        data.user_id = this.props.user.id
        data.status = this.state.status

        const API = new WorldAPIClient(this.props.user.token);

        const response = await API.createWorld(data)

        if (response.data
          && true === response.data.success) {
          this.setState({
            uploadSuccessful: true,
            worldId: response.data.worldId,
            world: response.data.world,
            uploading: false
          })
        }
      } catch(err) {
        console.error(err)
        this.props.addError(err)
        this.setState({
          uploadFailed: true,
          uploading: false
        })
      }
    }
  }

  getReadableName(name) {
    return name.replace(/(_|-)/g, ' ').replace(/(\.[\w]{3,4})$/, '')
  }
}

export default connect(getStore, {
  addError,
})(WorldUploader);
