import React, { Component } from 'react';

import ConvertKitAPI from '../../../boxappapi/convertkit'
import {registerUser} from '../../../boxappapi/user_client'
import BoxAppValidation from '../../../boxappapi/axios/validations'

import { connect } from 'react-redux'
import {storeUser, addError, addActivityIndicator} from "../../../redux/actions"

import InputField from '../../../lib/helpers/InputField'

import {
  Button,
} from '../../../lib/styles/forms'

import {
  Row,
  Column,
} from './RegistrationForm.components'

import './RegistrationForm.scss'

const convertKitSequenceNames = [
  'PERSONAL USE',
  'BUSINESS USE',
];

const convertKitTagNames = [
  'For Fun',
  'For Business',
];

class RegistrationForm extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
  		registered: false,

  		username: '',
  		email: '',
  		password: '',

      firstName: '',
      convertKitTag: '0',

      tags: [],
      sequences: [],

      thankYouPage: props.thankYouPage || '/register-success'
  	}
  }

  componentWillMount() {
    // this.loadConvertKitSequences()
    // this.loadConvertKitTags()
  }

  render() {

    // if (this.state.registered) {
    //   return <RegistrationFormSuccess />
    // }

    return (
      <div className="App-Login">
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className={'registration-form'}>

          <div className={'required-fields'}>
            {/*<Row>
              <Column>
                <InputField
                  type={'text'}
                  name={'firstName'}
                  id={'firstName'}
                  value={this.state.firstName}
                  placeholder={'First Name'}
                  onChange={this.updateState.bind(this)} />
              </Column>
              <Column>
                <InputField
                  type={'text'}
                  name={'username'}
                  id={'username'}
                  value={this.state.username}
                  placeholder={'username'}
                  onChange={this.updateState.bind(this)} />
              </Column>
            </Row>*/}
            <Row>
              <Column>
                <InputField
                  type={'email'}
                  name={'email'}
                  id={'email'}
                  value={this.state.email}
                  placeholder={'email'}
                  onChange={this.updateState.bind(this)} />
              </Column>
              <Column>
                <InputField
                  type={'password'}
                  name={'password'}
                  id={'password'}
                  value={this.state.password}
                  placeholder={'password'}
                  onChange={this.updateState.bind(this)} />
              </Column>
            </Row>
          </div>

          {/*<div className={'optional-fields'}>
            <span>How will you use boxapp?</span>
            {this.state.tags
              && 0 < this.state.tags.length
              && <InputField
                type={'select'}
                name={'convertKitTag'}
                id={'convertKitTag'}
                value={this.state.convertKitTag}
                onChange={this.updateState.bind(this)}
                options={this.getCKTagsOptions()} />}
          </div>*/}
          <Button
            type={'submit'}
          >
            Register
          </Button>
        </form>
      </div>
    );
  }

  updateState(input) {
    const { target } = input;
    const newState = {}
    newState[target.name] = ('username' === target.name) ? target.value.toLowerCase() : target.value
    this.setState(Object.assign({}, this.state, newState))
  }

  getCKTagsOptions() {

    const _options = [<option
      key={-1}
      value={0}>
      Select one
    </option>]
    const __options = this.state.tags.map((tag, idx) => (
      <option
        key={idx}
        value={tag.id}>
        {tag.name}
      </option>
    ))
    return [..._options, ...__options]
  }

  getSequenceFromTag() {
    let sequence = null
    if ('0' !== this.state.convertKitTag) {
      const tagID = +this.state.convertKitTag
      const tags = this.state.tags
      tags.forEach(tag => {
        if (tagID === tag.id) {
          if (-1 !== tag.name.indexOf('Business')) {
            // get business sequence
            sequence = this.getSequenceByName('BUSINESS USE')
          } else {
            // get personal sequence
            sequence = this.getSequenceByName('PERSONAL USE')
          }
        }
      })
    }
    return sequence
  }

  getSequenceByName(name) {
    name = name || ''
    let sequence = null
    this.state.sequences.forEach(seq => {
      if (name === seq.name) {
        sequence = seq
      }
    })
    return sequence;
  }

  async loadConvertKitSequences() {
    try {
      const __courses = await ConvertKitAPI.getSequences()
      const { courses } = __courses
      let authorizedCourses = []
      courses.forEach((course, i) => {
        if (-1 !== convertKitSequenceNames.indexOf(course.name)) {
          // we found a match
          authorizedCourses = [...authorizedCourses, course]
        }
      })
      this.setState({ sequences: authorizedCourses })
    } catch(err) {
      console.error(err)
    }
  }

  async loadConvertKitTags() {
    try {
      const __tags = await ConvertKitAPI.getTags()
      const { tags } = __tags
      let authorizedTags = []
      tags.forEach((tag, i) => {
        if (-1 !== convertKitTagNames.indexOf(tag.name)) {
          // we found a match
          authorizedTags = [...authorizedTags, tag]
        }
      })
      this.setState({ tags: authorizedTags })
    } catch(err) {
      console.error(err)
    }
  }

  // sunscribe to convertikit
  async subscribeToCK() {
    try {
      const sequenceID = this.getSequenceFromTag().id
      const subscriber = {
        first_name: this.state.firstName,
        email: this.state.email,
        tags: [+this.state.convertKitTag],
      };
      const response = await ConvertKitAPI.addSubscriberToSequence(
        sequenceID,
        subscriber
      )
      return response
    } catch (err) {
      console.error(err)
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.props.addActivityIndicator('Registering..')

    if (!this.validateForm())
    	return;

    const registerData = {
      username: this.state.email,
      email: this.state.email,
      password: this.state.password,
    }

    try {
      const response = await registerUser(registerData)
      console.log(response)
      if (true === response.success && 0 < response.id) {
        // this.subscribeToCK()
  	    // this.setState({ registered: true, })
        if ('function' === typeof this.props.onSuccess) {
          this.props.onSuccess(true)
        }
      }
    } catch(err) {
      console.error(err.message)
      this.props.addError(err)
      this.props.addActivityIndicator('')
    }

    this.props.addActivityIndicator('')
  }

  validateForm() {
    try {
      // validate Name
      // BoxAppValidation.validateName(this.state.firstName)
      // validate Username
      // BoxAppValidation.validateUsername(this.state.username)
      // validate Email
      BoxAppValidation.validateEmail(this.state.email)
      // validate Password
      BoxAppValidation.validatePassword(this.state.password)
      // validate convertkit tag
      // BoxAppValidation.validateCKTag(this.state.convertKitTag)
    } catch(err) {
      console.error(err)
      this.props.addError(err)
      this.props.addActivityIndicator('')
      return false
    }
    // passed validation
    return true;
  }
}

export default connect(null, {
  storeUser,
  addError,
  addActivityIndicator,
})(RegistrationForm);
