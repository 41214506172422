import React, { Component } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TumblrShareButton,
  WorkplaceShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  TumblrIcon,
  WorkplaceIcon,
  EmailIcon,
} from 'react-share';

const Embed = (props) => {

  const embedCode = [
    '<div style="position:relative;padding-top:54.6%;">',
    `<iframe`,
    ` src="${props.url}"`,
    ` frameborder="0"`,
    ` style="position:absolute;top:0;left:0;width:100%;height:100%;"`,
    ` allowfullscreen>`,
    `</iframe>`,
    '</div>'
  ]

  return(<div>
    <h5>Or, Embed Your World:</h5>

    <p>To embed this World into your
     website, copy and paste the code below.</p>

    <pre>
      <code>{embedCode.join('')}</code>
    </pre>
  </div>)
}

class SocialMediaShare extends Component {

  constructor(props) {
    super(props)

    this.state = {
      url: props.url || 'https://boxapp.io/',
      open: false,
    }
  }

  render() {
    return (
      <span style={{position: 'relative'}}>
        <a
          {...this.props}
          href={'/#social-sharing-button'}
          onClick={this.openSharingDialog}>
          {this.props.children}
        </a>

        {this.state.open
          && this.displaySharingDialog()}
      </span>
    )
  }

  openSharingDialog = (e) => {
    e.preventDefault();

    this.setState({open: (!this.state.open)})
  }

  displaySharingDialog = () => {
    return (<div className={'social-sharing-dialog'}>
      <div className={'social-sharing-dialog-inner'}>
        <div className={'social-sharing-dialog-header'}>
          <a
            href={'#close-dialog'}
            className={'close-dialog'}
            onClick={this.openSharingDialog.bind(this)}>
            x
          </a>
          <h5>Share Your World:</h5>
        </div>


        <div className={'sharing-options'}>
          <FacebookShareButton url={this.state.url}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          {/*<GooglePlusShareButton url={this.state.url}>
            <GooglePlusIcon size={32} round={true} />
          </GooglePlusShareButton>*/}
          <LinkedinShareButton url={this.state.url}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton url={this.state.url}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={this.state.url}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <RedditShareButton url={this.state.url}>
            <RedditIcon size={32} round={true} />
          </RedditShareButton>
          <TumblrShareButton url={this.state.url}>
            <TumblrIcon size={32} round={true} />
          </TumblrShareButton>
          <WorkplaceShareButton url={this.state.url}>
            <WorkplaceIcon size={32} round={true} />
          </WorkplaceShareButton>
          <EmailShareButton url={this.state.url}>
            <EmailIcon size={32} round={true} />
          </EmailShareButton>

          <Embed  url={this.state.url} />

          {/*<TelegramShareButton url={this.state.url}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <PinterestShareButton url={this.state.url} media={''}>
            <PinterestIcon size={32} round={true} />
          </PinterestShareButton>
          <VKShareButton url={this.state.url}>
            <VKIcon size={32} round={true} />
          </VKShareButton>
          <OKShareButton url={this.state.url}>
            <OKIcon size={32} round={true} />
          </OKShareButton>
          <LivejournalShareButton url={this.state.url}>
            <LivejournalIcon size={32} round={true} />
          </LivejournalShareButton>
          <ViberShareButton url={this.state.url}>
            <ViberIcon size={32} round={true} />
          </ViberShareButton>
          <MailruShareButton url={this.state.url}>
            <MailruIcon size={32} round={true} />
          </MailruShareButton>
          <LineShareButton url={this.state.url}>
            <LineIcon size={32} round={true} />
          </LineShareButton>*/}
        </div>
      </div>
    </div>)
  }
}

export default SocialMediaShare