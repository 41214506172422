import React, {useState} from 'react';
import RegistrationForm from '../../components/forms/RegistrationForm/RegistrationForm'
import RegistrationFormSuccess from '../../components/forms/RegistrationForm/RegistrationFormSuccess'
import {
  MaxWidthContent
} from '../../lib/styles/layout'

// import {} from '../../lib/styles/typography'

import {
	RegistrationWrapper,
	RegFormWrapper,
} from './styles'

import {
  AppLogo,
} from '../WelcomeScreen/styles'

const Register = (props) => {

  const [success, setSuccess] = useState(false)

  return (<RegistrationWrapper>
    <MaxWidthContent
      width={`800px`}
    >
    	<AppLogo>
    		<img
          src={'/images/logo/logo.png'}
          alt={'BoxApp Logo'} />
    	</AppLogo>

      {success
        ? <RegistrationFormSuccess
          goToLogin={props.goToLogin}
        />
        : <RegFormWrapper
          	bgColor={`rgba(10,10,10,0.7)`}
          >
            <h1>Register</h1>
          	<RegistrationForm
              onSuccess={(bool) => setSuccess(bool)}
            />
          </RegFormWrapper>}
    </MaxWidthContent>
  </RegistrationWrapper>)
}

export default Register