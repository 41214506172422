import React from 'react';
import ReactDOM from 'react-dom';
// workers
import registerServiceWorker from './registerServiceWorker';
// redux
import { Provider } from 'react-redux'
import store from './redux/store'
//
import AppPreLoader from './AppPreLoader'
import './styles/index.scss';

ReactDOM.render(<Provider store={store}>
	<AppPreLoader initialMsg={`Loading an amazing experience...`} />
</Provider>, document.getElementById('root'));
registerServiceWorker();
