import React, {useState} from 'react';
// redux
import { connect } from 'react-redux'
import {addError, addActivityIndicator} from "../../../redux/actions"
// api
import TooltipAPIClient from '../../../boxappapi/tooltip_client'
// components
import ListTooltips from './ListTooltips'
import './Tooltips.css'

import {P} from '../../../lib/styles/typography'

import {
  EditTTList,
  TTListEmpty
} from './styles'

function EditTooltips(props) {

  const {
    worldID,
    user,
    updatePreview,
  } = props

  const [tooltips, setTooltips] = useState(props.tooltips)

  const saveTooltip = async (newTT) => {
    props.addActivityIndicator('saving tooltip..')
    try {
      const TTApi = new TooltipAPIClient(user.token)
      const response = await TTApi.updateTooltip(newTT)
      if (response.data
        && response.data.id) {
        let __newTT = response.data.tooltip
        if (0 === newTT.id) {
          // we just updated a brand new tooltip
          // tell the parent container about it.
          __newTT = Object.assign({}, newTT, response.data.tooltip)
        }
        reloadTooltips()
        props.addActivityIndicator('')
        return __newTT;
      }
    } catch (err) {
      console.error(err)
      props.addError(err)
    }
    props.addActivityIndicator('')
  }

  const reloadTooltips = async () => {
    try {
      const response = await TooltipAPIClient.getTooltips(worldID)
      if (response.data
        && response.data.success) {
        updatePreview('tooltips')
        setTooltips(response.data.tooltips)
      }
    } catch (err) {
      console.error(err)
      addError(err)
    }
  }

  const deleteTooltip = async (tooltipID) => {
    props.addActivityIndicator('Removing tooltip..')
    const args = {
      id: tooltipID,
      world_id: worldID,
    }
    try {
      const TTApi = new TooltipAPIClient(user.token)
      const response = await TTApi.deleteTooltip(args)

      if (response.data
        && response.data.success) {
        reloadTooltips()
      }
    } catch (err) {
      console.error(err)
      props.addError(err)
    }
    props.addActivityIndicator('')
  }

  return (
    <EditTTList>
      {0 === tooltips.length
        && <TTListEmpty>
          <P>No tooltips have been created yet.</P>
        </TTListEmpty>}

      <ListTooltips
        tooltips={tooltips}
        worldID={worldID}
        user={user}
        saveTooltip={saveTooltip}
        deleteTooltip={deleteTooltip}
      />
    </EditTTList>
  );
}

export default connect(null, {addError, addActivityIndicator})(EditTooltips);
