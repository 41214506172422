import styled from 'styled-components'

export const MenuSectionWrapper = styled.div`

`

export const MenuSectionTitle = styled.div`
	// opacity: 0.5;
	background-color: #f6f6f6;
	text-align: center;
	padding: 0.5em 0;

	h5 {
		color: #444;
		letter-spacing: 3px;
		padding: 0.5em 0.25em 0 0.25em;
		margin: 0;
		padding-bottom: 0.5em;
		text-transform: uppercase;
		font-size: 65%;
	}
`

export const MenuSectionInner = styled.div`

`