import React from 'react';

import EditWorldForm from './EditWorldForm'
import SlideInNav from '../../../components/Navigation/SlideInNav/SlideInNav'


function EditWorldSidebar({world, updatePreview, user, goBack}) {

  return (
    <SlideInNav
      direction={'right'}
      preventAutoClose
      size={100}
    >
      <EditWorldForm
        updatePreview={updatePreview}
        world={world}
        user={user}
        goBack={goBack}
      />
    </SlideInNav>
  );
}

export default EditWorldSidebar