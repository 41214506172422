import React, {Fragment, useState, useEffect} from 'react'
import ListWorlds from '../../components/World/ListWorlds'
import BottomNav from '../../components/Navigation/BottomNav'
import SearchBar from '../../components/SearchBar'
import Header from '../../components/Header'

import LoadingIndicator from '../../lib/helpers/LoadingIndicator'

import WorldAPIClient from '../../boxappapi/world_client'
// add redux
import { connect } from 'react-redux'
import {getStore} from "../../redux/selectors"
import {
  addActivityIndicator,
  addError
} from "../../redux/actions"

import {
	Intro,
  NoResults,
} from './styles'

const Search = (props) => {

	const [phrase, setPhrase] = useState(props.match.params.keyword || '')
	const [worlds, setWorlds] = useState([])

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		preformSearch()
	}, [phrase])

	const preformSearch = async () => {
		setLoading(true)
		try {
      const response = await WorldAPIClient.searchWorlds({
      	keywords: phrase
      })
      response && setWorlds(response.worlds)
    } catch(err) {
      props.addError(err)
    }
    setLoading(false)
	}

	return (
		<Fragment>
			<Header>
			<SearchBar
				search={keyword => setPhrase(keyword)}
				clear={() => {
					setWorlds([])
					setPhrase('')
				}}
			/>
			</Header>

			{loading && <LoadingIndicator />}

			{!loading
				&& '' === phrase
				&& <Intro>
					Begin typing to search for worlds.
				</Intro>}

			{!loading
				&& 0 < worlds.length
				&& <ListWorlds worlds={worlds}  />}

			{!loading
				&& 0 === worlds.length
				&& '' !== phrase
				&& <NoResults>
					Hmm...
					<br />
					We did not find ay worlds with that keyword.
				</NoResults>}

			<BottomNav />
		</Fragment>
	)
}

export default connect(getStore, {
	addActivityIndicator,
  addError
})(Search)