import styled from 'styled-components'

import {Colors} from '../../lib/styles/styles'

export const HeaderContainer = styled.header.attrs(props => ({
	id: props.id || 'header',
	className: props.className || 'App-header',
}))`
  position: fixed;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
  box-sizing: border-box;
  // border-bottom: 0.1em solid ${Colors.lightGray};
  padding: .5em;
  z-index: 999999;
  background-color: ${props => props.background || Colors.opaqueWhite};
  text-align: center;

  &:before,
  &:after {
  	content: '';
  	display: block;
  	clear: both;
  }
`

export const HeaderNavigation = styled.div`
`

export const HeaderTitle = styled.div`
	display: block;
	position: absolute;
	top: 0; left: 15%;
	height: 100%;
	font-size: 1.35em;
	width: 70%;
	padding: 0.5em;
	box-sizing: border-box;
	background-color: transparent;
	overflow: hidden;
`

const navItem = styled.div`
	min-height: 1.8em;
	a,.link {
		border-bottom: 0;
		margin-top: 0.25em;
		line-height: 1;
		color: ${Colors.brightBlue};
		text-decoration: none;
		line-height: 1.8em;
		display: inline-block;
	}
`

export const LeftNav = styled(navItem)`
	float: left;
`

export const RightNav = styled(navItem)`
	float: right;
`