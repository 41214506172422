import styled from 'styled-components'

import {Colors} from './styles'

export const Form = styled.form`
	max-width: 600px;
	margin: 0 auto;
`

const __input = `
	margin: 0.5em 0;
	font-size: 0.85em;
	display: inline-block;
`

const __textBlock = `
	box-sizing: border-box;
	line-height: 2em;
	width: 100%;
	padding: 0.25em;
	appearance: none;
	-webkit-appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 3px;
`

export const Input = styled.input.attrs(props => ({}))`
	${__input}

	${props => props.type
		&& props.type !== "button"
		&& props.type !== "submit"
		&& props.type !== "file"
		&& props.type !== "checkbox"
		&& props.type !== "radio"
		&& __textBlock}
`

export const Select = styled.select.attrs(props => ({}))`
	${__input}

	${__textBlock}
`

export const Textarea = styled.textarea`
	${__input}
	${__textBlock}
	min-height: 6em;
	width: 100%;
`

export const Label = styled.label.attrs(props => ({}))`
	display: inline-block;
	margin: 1em 0 0.25em;
	font-weight: 700;

	+ i {
		display: block;
		margin-top: 0.5em;
		font-size: 0.75em;
	}
`

export const Button = styled.button.attrs(props => ({
	small: props.small || false,
	large: props.large || false,
	color: props.color || Colors.blue,
	bg: props.bg || Colors.white,
}))`
	font-size: ${props => props.small ? '0.75em'
		: props.large ? '1.5em' : '1em'}
	display: inline-block;
	border-width: 0.125em;
	border-style: solid;
	border-color: ${props => props.color};
	color: ${props => props.color};
	text-decoration: none;
	appearance: none;
	webkit-appearance: none;
	padding: 0.75em 2em;
	text-transform: uppercase;
	margin: 1em 0 1em 0;
	background-color: ${props => props.bg};
	transition: all .4s ease-in-out;
	cursor: pointer;
	text-align: center;
`

export const DeleteButton = styled(Button)`
	color: ${Colors.red};
	border-color: ${Colors.red};
`