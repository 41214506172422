import React, {useState, useEffect} from 'react';
import {Redirect} from "react-router-dom";
// components
//
import HandleError from './lib/helpers/HandleError'
import ActivityIndicator from './lib/helpers/ActivityIndicator'
import './App.scss';

// redux
import { connect } from 'react-redux'
import {storeUser, addError, addActivityIndicator} from "./redux/actions"
import {getStore} from "./redux/selectors"

import useWindowResize from './lib/hooks/useWindowResize'

const App = (props) => {

  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  const [width] = useWindowResize()

  useEffect(() => getAppHeight())
  useEffect(() => getAppHeight(), [width])

  const getAppHeight = () => {
    const header = document.getElementById(`header`)
    const headerHeight = header
      ? (header.offsetHeight)
      : 0
    const footer = document.getElementById(`BottomNav`)
    const footerHeight = footer
      ? (footer.offsetHeight)
      : 0

    setHeaderHeight(headerHeight)
    setFooterHeight(footerHeight)
  }

  const displayErrors = () => {
    if (0 < props.errors.notifications.length) {
      return (<div className={'errors-container'}>
        {props.errors.notifications.map( (error, i) => {
          return <HandleError key={i} error={error} errorKey={i} />
        })}
      </div>)
    }
  }

  const displayActivityIndicator = () => {
    if (props.activityIndicator
      && 0 < props.activityIndicator.message.length) {
      return <ActivityIndicator message={props.activityIndicator.message} />
    }
  }

  if (!props.user.authenticated
    && !props.isLoginPage
    && !props.isRegisterPage) {
    return <Redirect to={'/login'} />
  }

  return (
    <div className="App" style={{
      minHeight: '100vh',
      paddingTop: headerHeight,
      paddingBottom: footerHeight,
    }}>
      <main className={props.page}>
        {displayErrors()}
        {props.children}
      </main>

      {displayActivityIndicator()}

    </div>
  )
}

export default connect(getStore, {storeUser, addError, addActivityIndicator})(App);
