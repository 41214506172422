import React, { useState } from 'react';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import api from '../../../boxappapi/index'
import WorldAPIClient from '../../../boxappapi/world_client'

import InputField from '../../../lib/helpers/InputField'
import DragNDrop from '../DragNDrop/DragNDrop';
import MediaPreview from '../../../lib/helpers/MediaPreview'

import {
	AlignRight
} from '../../../lib/styles/layout'

import {
  Button
} from '../../../lib/styles/forms'


const WorldInfo = (props) => {

	const {
		pano_media,
		pano_video,
		post_status,
		// post_author,
		post_content,
		post_title,
	} = props.currentWorld

	const [pano, setPano] = useState(pano_media)
	const [panoVideo, setPanoVideo] = useState(pano_video)

	const [status, setStatus] = useState(post_status)
	const [name, setName] = useState(post_title)
	const [description, setDescription] = useState(post_content)

  const removeVideo = e => {
  	e.preventDefault()
  	setPanoVideo(null)
  }

  const _saveWorld = async () => {
  	props.addActivityIndicator('Updating world...')

    if (props.user
      && props.user.token
      && (0 < props.user.id)) {

      try {
        const data = {
          user_id: props.user.id,
          status: ('' !== status) ? status : props.currentWorld.post_status,
          name: name,
          description: description,
          pano_media: pano,
          pano_video: panoVideo,
        }

        const API = new WorldAPIClient(props.user.token);
        const response = await API.updateWorld(props.currentWorld.ID, data)

        if (response.data
          && true === response.data.success) {
          props.updatePreview()
          props.addActivityIndicator('')
        }
      } catch (err) {
        props.addError(err)
    		props.addActivityIndicator('')
      }
    }
  }

  const __submit = (e) => {
  	e.preventDefault();
  	_saveWorld()
  }

	return (<form>
		<h4>360 Image</h4>
    <DragNDrop
      message={'Upload a 360 Image'}
      onDrop={files => setPano(files[0])}>
      {(pano)
        && <MediaPreview media={{
            panoMedia: pano
          }} />}
    </DragNDrop>

    <h4>360 Video</h4>
    <DragNDrop
      message={'Upload a 360 Video'}
      onDrop={files => setPanoVideo(files[0])}>
      {(panoVideo)
        && <MediaPreview media={{
            panoVideo: panoVideo
          }} />}
    </DragNDrop>

    {(panoVideo)
      && <button
        className={'link'}
        onClick={removeVideo}>
        Remove Video
      </button>}

    {(api.isOrg())
      && <InputField
        type={'select'}
        name={'status'}
        id={'status'}
        defaultValue={status}
        label={'Status:'}
        onChange={e => setStatus(e.target.value)}
        options={[
          <option key={'draft'} value={'draft'}>Draft</option>,
          <option key={'publish'} value={'publish'}>Publish</option>,
          <option key={'private'} value={'private'}>Private</option>,
        ]}
       />}

    <InputField
      label={'Rename World:'}
      type={'text'}
      name={'name'}
      id={'name'}
      value={name}
      placeholder={'name'}
      onChange={e => setName(e.target.value)} />

    <InputField
      type={'textarea'}
      name={'description'}
      id={'description'}
      label={'Update Description:'}
      value={description}
      placeholder={'description'}
      onChange={e => setDescription(e.target.value)} />

    <AlignRight>
    	<Button
        small
    		onClick={__submit}
    	>
    		Save
    	</Button>
    </AlignRight>
  </form>)
}


export default connect(getStore, {
  addActivityIndicator,
  addError,
})(WorldInfo);
