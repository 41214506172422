import React, {useState, Fragment} from 'react'
// components
import EditTooltip from './EditTooltip'
import TooltipItem from './TooltipItem'

import {
	NewTT
} from './styles'

import {
	Button
} from '../../../lib/styles/forms'

function ListTooltips({
	tooltips,
	worldID,
	user,
	deleteTooltip,
	saveTooltip,
	newTooltip
}) {

	const [activeTooltip, setActiveTooltip] = useState(null)

	const __closeTooltip = () => setActiveTooltip(null)

	const __openTooltip = (tt) => setActiveTooltip(tt)

	const __deleteTooltip = (ttID) => {
		deleteTooltip(ttID)
		setActiveTooltip(null)
	}

	const __createTooltip = async (e) => {
    e.preventDefault()
    // compose a new tooltip
    const __newTooltip = {
      id: 0, // id must be zero in order to create a new one
      name: 'New Tooltip',
      world_id: worldID,
      description: '',
    }
    // save it
    const newTT = await saveTooltip(__newTooltip)
    setActiveTooltip(newTT)
  }

  const __saveTooltip = (newTT) => {
  	saveTooltip(newTT)
  	setActiveTooltip(null)
  }

	if (activeTooltip) {
    return <EditTooltip
      tooltip={activeTooltip}
      worldID={worldID}
      user={user}
      onDelete={__deleteTooltip}
      onSave={__saveTooltip}
      closeTooltip={__closeTooltip}
    />
	}

	return <Fragment>

		{tooltips
      && tooltips.map((tt, i) => {
	    return <TooltipItem
	      tooltip={tt}
	      key={i}
	      openTT={__openTooltip} />
	  })}

	  <NewTT>
      <Button
      	small
        onClick={__createTooltip}
      >
      	+ Tooltip
      </Button>
    </NewTT>

	</Fragment>

}

export default ListTooltips
