import React, { useState } from 'react';
import {logIn, forgotPassword, forgotUsername} from '../../boxappapi/user_client'

import InputField from '../../lib/helpers/InputField'

import { connect } from 'react-redux'
import {getUser} from "../../redux/selectors"
import {storeUser, addError, addActivityIndicator} from "../../redux/actions"
import {
  CenteredText,
} from '../../lib/styles/typography';

import {
  Button
} from '../../lib/styles/forms';

import {
  MaxWidthContent
} from '../../lib/styles/layout'

import {
  LoginWrapper,
  LoginFormWrapper,
} from './Login.styles'

import {
  AppLogo,
} from '../WelcomeScreen/styles'

const Login = (props) => {

  const {onLogin} = props

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const _forgotPassword = async (e) => {
    e.preventDefault();

    props.addActivityIndicator('Resetting your password..')

    if ('' === username) {
      props.addError({
        message: 'You must enter a username to retrieve your password.'
      })
      props.addActivityIndicator('');
      return;
    }

    try {
      const response = await forgotPassword( username )
      if (true === response.success) {
        alert('A temporary password has been sent to your email.')
      }
    } catch(err) {
      console.error(err)
    }
    props.addActivityIndicator('')
  }

  const _forgotUsername = async (e) => {
    e.preventDefault();

    props.addActivityIndicator('Retrieveing your username..')

    const email = prompt('Enter your email address')

    if (!email) {
      props.addError({
        message: 'You must enter an email to retrieve your username.'
      })
      props.addActivityIndicator('');
      return;
    }

    try {
      const response = await forgotUsername( email )
      if (true === response.success) {
        alert('We sent you an email with your username.')
      }
    } catch(err) {
      console.error(err)
    }
    props.addActivityIndicator('')
  }

  const doLogin = async () => {
    props.loginStarted()
    props.addActivityIndicator('logging you in..')
    try {
      const response = await logIn({username, password})
      if (!response) {
        props.addError({ message: `That didn't work. Check your username and password and try again.` })
        return;
      }
      props.addActivityIndicator('');
      onLogin(response)
    } catch(error) {
      console.error(error)
      props.addError(error)
      props.addActivityIndicator('');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    doLogin()
  }

  return (
    <LoginWrapper>
      <MaxWidthContent
        width={`800px`}
      >
        <AppLogo>
          <img
            src={'/images/logo/logo.png'}
            alt={'BoxApp Logo'} />
        </AppLogo>
        <LoginFormWrapper bgColor={`rgba(10,10,10,0.7)`}>
          <h1>Login</h1>
          <form
            onSubmit={handleSubmit}
            className={'login-form'}>

            <InputField
              type={'text'}
              name={'username'}
              id={'username'}
              value={username}
              placeholder={'Email / Username'}
              onChange={e => setUsername(e.target.value.toLowerCase())} />

            <InputField
              type={'password'}
              name={'password'}
              id={'password'}
              value={password}
              placeholder={'password'}
              onChange={e => setPassword(e.target.value)} />

            <Button
              type={'submit'}
            >
              Login
            </Button>

            <CenteredText>
              <div
                className={`forgot-password`}
              >
                <a
                  href={`#forgot-password`}
                  className={`link`}
                  onClick={_forgotPassword}>
                  Forgot Password
                </a>
              </div>
              <div
                className={`forgot-username`}
              >
                <a
                  href={`#forgot-password`}
                  className={`link`}
                  onClick={_forgotUsername}>
                  Forgot Username
                </a>
              </div>
            </CenteredText>
          </form>
        </LoginFormWrapper>
      </MaxWidthContent>
    </LoginWrapper>
  )
}

export default connect(getUser, {
  storeUser,
  addError,
  addActivityIndicator
})(Login);
