import React, { Component } from 'react';
import ReactPlayer from 'react-player'

export default class MediaPreview extends Component {
  render () {
    if (this.props.media.panoVideo
      && this.props.media.panoVideo.uri) {
      if (null !== this.props.media.panoVideo.uri.match(/(\.mp4)$/i)) {
        // this is a video
        return (<div className={'media-preview'}>
          <ReactPlayer
            className={'video-player'}
            url={this.props.media.panoVideo.uri} controls />
        </div>)
      }
    } else
    if (this.props.media.panoMedia
      && this.props.media.panoMedia.uri) {
      return (<div className={'media-preview'}>
        <div style={{
          backgroundImage: 'url('+this.props.media.panoMedia.uri+')'
        }} />
      </div>)
    } else
    if (this.props.media.uri
      && this.props.media.uri) {
      return (<div className={'media-preview'}>
        <div style={{
          backgroundImage: 'url('+this.props.media.uri+')'
        }} />
      </div>)
    }
    return null;
  }
}