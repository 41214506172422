import React from 'react';
import BreadCrumb from './BreadCrumb'

import {BreadCrumbsWrapper} from './SlidingTreeNav.components'

const BreadCrumbs = ({crumbs}) => {

	if (1 >= crumbs.length) {
		return null
	}

	return (<BreadCrumbsWrapper>
		{crumbs.map((crumb, idx) => {
			return <BreadCrumb key={idx} id={idx} {...crumb} />
		})}
	</BreadCrumbsWrapper>)
}


export default BreadCrumbs