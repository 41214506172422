import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import './DragNDrop.scss'

export default function DragNDrop(props) {

  const onDrop = useCallback(acceptedFiles => {

    if (props.onDrop && 'function' === typeof props.onDrop) {
      props.onDrop(acceptedFiles)
    }

  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const defaultMessage = `Click me, or Drag 'n Drop files here.`;

  return (
    <div className={`Drag-N-Drop ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
      <input className={'dnd-file-input'} {...getInputProps()} />
      {undefined !== props.children
        && "" !== props.children
        && null !== props.children
        ? props.children
        : <div className={'dnd-box'}>
          <p>{props.message || defaultMessage}</p>
        </div>}
    </div>
  )
}