const initialState = {
  myWorlds: [],
  allWorlds: [],
}

const updateWorld = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_WORLDS_FOR_USER": {
      const { worlds } = action.payload
      return Object.assign({}, state, {
        myWorlds: worlds,
      });
    }

    case "STORE_ALL_WORLDS": {
      const { worlds } = action.payload

      return Object.assign({}, state, {
        allWorlds: worlds,
      });
    }

    default: {
      return state;
    }
  }
};

export default updateWorld;
