import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";

import { connect } from 'react-redux'
import {getStore} from "../../redux/selectors"

import InputField from '../../lib/helpers/InputField'
import './SearchBar.scss'

class SearchBar extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
  		keyword: '',
  	}

    this.resetKeyUp = false;
    this.searchField = React.createRef();
  }

  componentDidMount() {
    // console.log(this.searchField.current)
  }

  render() {
    return (<div className={'search-bar'}>
      <form
        onSubmit={e => {
          e.preventDefault();
          this.performSearch()
        }}>
        <InputField
          ref={this.searchField}
        	type={'text'}
        	name={'keyword'}
        	placeholder={'Search..'}
        	value={this.state.keyword}
        	className={'search-bar-field'}
        	onChange={this.updateState.bind(this)}
          onKeyUp={this.checkWhenToSearch.bind(this)} />

        {0 < this.state.keyword.length
          && <button
            className={'btn reset'}
            type={'reset'}
            onClick={e => {
              e.preventDefault();
              this.doClear()
            }}>x</button>}
      </form>
    </div>)
  }

  updateState(input) {
    const { target } = input;
    const newState = {}
    newState[target.name] = target.value
    this.setState(newState, this.checkToReset.bind(this))
  }

  checkToReset() {
    if (0 === this.state.keyword.length) {
      this.doClear();
    }
  }

  checkWhenToSearch() {

    if (this.resetKeyUp) {
      // this prevents multiple API calls when the user is typing
      return
    }

    this.resetKeyUp = true;
    // use this state to indicate any loading animation, etc
    clearTimeout(this.timeInterval)
    this.timeInterval = setTimeout(this.performSearch.bind(this), 1000);
  }

  doClear() {
  	this.setState({
  		keyword: '',
  	})

    this.resetKeyUp = false;
    this.props.clear()
  }


  performSearch() {
    this.resetKeyUp = false;
    if (0 < this.state.keyword.length) {
      this.props.search( this.state.keyword )
    }
  }
}

export default connect(getStore, {})(SearchBar);
