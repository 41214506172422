import React from 'react'
import styled from 'styled-components'

import {
	Colors
} from '../../../lib/styles/styles'

import SocialMediaShare from '../../../lib/helpers/SocialMediaShare'

export const WorldSharingButtonsWrapper = styled.div`
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // width: 100%;
  // text-align: center;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  line-height: 1;
  margin: 0;
  background-color: rgba(${Colors.white}, 0.85);

`

export const SharingButton = styled.div`
	display: inline-block;

  margin-left: 0 0.75em;
  vertical-align: top;
  outline: 0 !important;

  img {
	  max-height: 100%;
	  width: auto;
	}

	a {
		display: block;
		width: 1.8em;
		margin: 0;
		text-decoration: none;
		outline: 0;
  	padding: 0 0.5em;
  	line-height: 1;
	}
`


export const WorldSharingButtons = ({worldUrl}) => {
	return (<WorldSharingButtonsWrapper>
		<SharingButton>
			<SocialMediaShare
	      url={worldUrl}
	      value={'this is the url'}>
	      <img src={'/images/icons/share thick.png'}
	      alt={'Share world icon'} />
	    </SocialMediaShare>
    </SharingButton>
    <SharingButton>
	    <a
	      href={worldUrl}
	      target={'_blank'}
	      rel={'noopener'}>
	      <img src={'/images/icons/360.svg'}
	      alt={'View world icon'} />
	    </a>
    </SharingButton>
	</WorldSharingButtonsWrapper>)
}