import React, { Component } from 'react';

class TableRow extends Component {

  render() {
    const count = this.props.children.length

    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        className: child.props.className + ' table-row-cell pwp-vertical-align-middle col'+count,
      });
    });

    return (
      <div className={`table-row pwp-inline flush-columns force-columns cols${count}`}>
        {children}
      </div>
    )
  }
}

export default TableRow