import React from 'react'
// import {Link} from "react-router-dom";

import {
	FooterElement
} from './styles'

import {version} from '../../../package.json';

const Footer = () => {

	const __date = new Date().getFullYear()

	return (
		<FooterElement>
      <p>
      	&copy; {`${__date} BoxApp.io | v. ${version}`}
      </p>
      {/*<p>
	      <Link
	        className="link"
	        to="/page/privacy-policy">
	        Privacy Policy
	      </Link>
	      &nbsp;&nbsp;|&nbsp;&nbsp;
	      <Link
	        className="link"
	        to="/page/terms-conditions">
	        Terms & Conditions
	      </Link>
      </p>*/}
    </FooterElement>
	)
}

export default Footer