import React, { useState } from 'react';
import {Redirect} from "react-router-dom";
import {createUser, userExists} from '../../../boxappapi/user_client'
import InputField from '../../../lib/helpers/InputField'
import {PageContent} from "../../../lib/styles/layout"
import OrgSubNav from '../SubNav/OrgSubNav'

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addError,
  addActivityIndicator
} from "../../../redux/actions"


import AdminPage from '../../../components/AdminPage/AdminPage'


function NewUser(props) {

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [roles, setRoles] = useState(['author'])
  const [userCreated, setUserCreated] = useState(false)
  const [userFound, setUserExists] = useState(-1)
  const [showFields, setShowFields] = useState(false)

  if (userCreated) {
    return <Redirect to='/org/manage-users' />
  }

  const checkForUser = async () => {

    props.addActivityIndicator('Checking if user exists..')

    try {
      const exists = await userExists({email}, props.user.token)

      setUserExists(exists ? 1 : 0)
      setShowFields(!exists)

      props.addActivityIndicator('')
    } catch(err) {
      console.error(err)
      props.addError(err)
      props.addActivityIndicator('')
    }
  }

  const saveUser = async () => {

    if (showFields && '' === username) {
      props.addError({message: 'Please add a username.'})
      props.addActivityIndicator('')
      return;
    }

    props.addActivityIndicator('Adding User..')


    try {
      const data = {roles}

      if ('' !== username) {
        data.username = username
      }

      if ('' !== email) {
        data.email = email
      }

      const user = await createUser(data, props.user.token)

      setUserCreated(user.user_added_to_blog)
      props.addActivityIndicator('')
    } catch(err) {
      console.error(err)
      props.addError(err)
      props.addActivityIndicator('')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ( -1 === userFound ) {
      checkForUser()
      return;
    }

    saveUser()
  }

  return (<AdminPage isAdmin isOrg>
    <OrgSubNav location={props.location} />
    <PageContent>
    <div className="User-Profile">

      <h1>New User</h1>

      <p>Let's begin by looking up the user with their email address.</p>

      <form
        onSubmit={handleSubmit}
        className={'user-profile-form'}
        autoComplete={'off'}>


        <InputField
          label={'Email:'}
          type={'email'}
          name={'email'}
          autoComplete={'off'}
          value={email || ''}
          onChange={e => {
            const val = e.target.value
            setEmail(val)
            setUserExists(-1)
            setShowFields(false)
          }} />

        {1 === userFound
          && <div>We found this user! Assign them the right Role before saving.</div>}

        {showFields
          && <div>It looks like no user exists with that email. Create a new username for them and assign them the right Role before saving.</div>}

        {showFields
          && <InputField
            label={'Username:'}
            type={'text'}
            name={'username'}
            autoComplete={'off'}
            value={username}
            onChange={e => setUsername(e.target.value)} />}

        {-1 !== userFound
          && <InputField
            label={'Role:'}
            type={'select'}
            name={'roles[]'}
            value={roles[0]}
            onChange={e => setRoles([e.target.value])}
            options={[
              <option key="guest" value="guest">Guest</option>,
              <option key="author" value="author">Author</option>,
              <option key="administrator" value="administrator">Admin</option>
            ]} />}

        <InputField
          type={'submit'}
          value={( -1 === userFound ) ? 'Look Up User' : 'Save User'} />
      </form>
    </div>
    </PageContent>
  </AdminPage>);
}

export default connect(getStore, {
  addError,
  addActivityIndicator
})(NewUser);
