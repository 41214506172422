import React from 'react';
import {Link} from 'react-router-dom'

import {
  BottomNavWrapper,
  BNLink,
  Icon,
} from './styles'

const BottomNav = () => {
  return (
    <BottomNavWrapper id={'BottomNav'}>
      <BNLink left>
        <Link to={'/'}>
          <Icon
            src={'/images/icons/home.svg'}
          />
        </Link>
      </BNLink>

      <BNLink>
        <Link to={'/search'}>
          <Icon
            src={'/images/icons/search.svg'}
          />
        </Link>
      </BNLink>

      <BNLink right>
        <Link to={'/new-world'}>
          <Icon
            src={'/images/icons/new-world.svg'}
          />
        </Link>
      </BNLink>
    </BottomNavWrapper>
  )
}

export default BottomNav