import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import {getPublicUser} from '../../boxappapi/user_client'
import MediaApiClient from '../../boxappapi/media_client'

import { connect } from 'react-redux'
import {storeOtherUsers} from "../../redux/actions"
import {getStore} from "../../redux/selectors"
import './UserAvatar.scss'

import {AvatarWrapper, AvatarIcon} from './UserAvatar.styles.js'

function UserAvatar(props) {
  const [user, setUser] = useState(props.userObj || null);
  const [userID] = useState(props.userID || 0);
  const [size] = useState(props.size || 48);
  const [avatarSrc, setAvatarSrc] = useState('');


  useEffect(() => {

    let _okToLoadUser = true;

    function loadCachedUser(userID) {
      // if (!_okToLoadUser) return; // removed, replaced with line 45
      const _user = props.user.otherUsers.find(usr => {
        return +userID === +usr.id
      })

      return _user
    }

    async function loadUser(userID) {
      // if (!_okToLoadUser) return; // removed, replaced with line 45
      try {
        const _user = await getPublicUser(userID)
        setUser(_user.user);
        props.storeOtherUsers(_user.user)
      } catch(err) {
        console.error(err)
      }
    }

    if (!_okToLoadUser) return;

    if (null === user && 0 !== userID) {
      const cachedUser = loadCachedUser(userID)
      if (cachedUser) {
        setUser(cachedUser)
      } else {
          loadUser(userID)
      }
    }

    return () => { _okToLoadUser = false }
  }, [user, userID])

  useEffect(() => {

    let _okToAjax = true;

    async function loadProfilePicture(user) {

      if (!_okToAjax) return;

      try {
        let pic


        if ("" !== user.profile_picture_blog_id) {
          const blogID = +user.profile_picture_blog_id
          const orgs = props.user.currentUser
            && props.user.currentUser.orgs
          if (orgs
            && orgs[blogID]) {
            pic = await MediaApiClient.getMediaFromOrg(
              user.profile_picture,
              orgs[blogID]
            );
          }
        } else {
          pic = await MediaApiClient.getMedia( user.profile_picture )
        }

        if (!pic) {
          return;
        }

        const avatarSrc = pic.media_details
          && pic.media_details.sizes
          && pic.media_details.sizes.thumbnail
          && pic.media_details.sizes.thumbnail.source_url

        if (_okToAjax) setAvatarSrc( avatarSrc )

      } catch( err ) {
        console.error( err )
      }
    }

    if (!_okToAjax) return;

    if (user && user.profile_picture) {
      loadProfilePicture(user)
    }

    return () => { _okToAjax = false }
  }, [user])

  if (!user) {
    return null;
  }

  const __imgSrc = avatarSrc && '' !== avatarSrc
    ? avatarSrc
    : (user.avatar_urls) && user.avatar_urls[size]

  return (
    <AvatarWrapper className={'user-avatar'}>
      {false !== props.link
        ? <Link to={`/user/${user.id}`}>
          <AvatarIcon imgSrc={__imgSrc} size={size} />
        </Link>
        : <AvatarIcon imgSrc={__imgSrc} size={size} />}
      {props.showName
        && <span>{user.name}</span>}
    </AvatarWrapper>
  );
}

export default connect(getStore, {storeOtherUsers})(UserAvatar);