import React from 'react'
import ReactPlayer from 'react-player'

function TooltipMedia(props) {
	if (!props.tooltip) {
    return null;
  }

  if (null !== props.tooltip.mediaSrc.match(/(\.mp4)$/i)) {
    // this is a video
    return (<div className={'media-preview'}>
      <ReactPlayer
        className={'video-player'}
        url={props.tooltip.mediaSrc} controls />
    </div>)
  }

  return (<div className={'media-preview'}>
    <div style={{
      backgroundImage: 'url('+props.tooltip.mediaSrc+')'
    }} />
  </div>)
}

export default TooltipMedia
