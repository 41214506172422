import cookie from 'react-cookies'
import api from './boxappapi/index'

class BoxappCookie {

  _domain = api.webDomain

  _cookieSaved = null

	get(key, options) {
    key = key || ''
    options = options || {}
    const _cookie = cookie.load(key, options)
    return ('string' === typeof _cookie)
      ? JSON.parse(_cookie) : _cookie;
	}

  set (key, value, options) {
    this.save(key, value, options)
  }

  save(key, value, options) {
    key = key || ''
    value = value || null
    options = options || {}

		cookie.save(
      key,
      value,
      Object.assign({}, options, {
        path: '/',
        domain: this._domain,
      })
    )
	}

  remove(key, options) {
    key = key || ''
    options = options || {}

    // const _domain = ('dev' === api.ENV) ? '.localhost' : '.boxapp.io'

    return cookie.remove(
        key,
        Object.assign({}, options, {
          path: '/',
          domain: this._domain,
        })
      )
  }

  async saveUserCookie(userData, callback) {
    // console.log(this._domain)
    if (userData.token && (userData.user || userData.id)) {
      // save the user in cookies
      const userCookie = {
        id: userData.user ? userData.user.id : userData.id,
        token: userData.token,
      }

      await this.save('boxappUser', userCookie);

      if ('function' === typeof callback) {
        this._cookieSaved = true
        // return the user info required for the store
        return callback(Object.assign({}, userCookie, {
          currentUser: userData.user,
          authenticated: true,
          // isAdmin: bool,
        }))
      }
    }
  }
}

export default new BoxappCookie();