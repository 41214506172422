import { combineReducers } from "redux";
import user from './user'
import world from './world'
import errors from './errors'
import activityIndicator from './activityIndicator'

export default combineReducers({
	user,
	world,
	errors,
	activityIndicator,
});
