import React, { Component } from 'react';

import {verify2FACode, resend2FACode} from '../../boxappapi/user_client'

import InputField from '../../lib/helpers/InputField'

import {
  PageH1
} from '../../lib/styles/typography'

import {
  MaxWidthContent
} from '../../lib/styles/layout'

import { connect } from 'react-redux'
import {getStore} from "../../redux/selectors"
import {storeUser, addError, addActivityIndicator} from "../../redux/actions"

class TwoFactorAuth extends Component {

  constructor(props) {
  	super(props)

  	this.state = {
  		user_id: props.user_id || 0,
  		code: props.code || '',
  		verified: false,
  		codeResent: false,
  	}

  	this.interval = null
  }

  componentWillUnmount() {
  	clearTimeout(this.interval)
  	this.interval = null;
  }

  render() {
  	return (<div className="Two-Factor-Auth">
      <PageH1>Two-Factor Authentication</PageH1>

      <MaxWidthContent
        width={`800px`}
        padding={'0 2em'}
      >

        <p>We sent you an email with a 6 digit code. Please check your email and enter the code below.</p>

        <form
          onSubmit={this.handleSubmit.bind(this)}
          className={'two-factor-auth-form'}>

          <InputField
            label={'Enter Code'}
            type={'text'}
            name={'code'}
            id={'code'}
            value={this.state.code}
            placeholder={'135246'}
            onChange={e => this.setState({code: e.target.value})} />

          <InputField
            type={'submit'}
            value={'Verify'} />
        </form>

        <p>Your code is valid for 15 minutes. Please allow a couple of minutes for the email to reach your inbox.</p>

        <div className={`pwp-inline`}>
          <a
            href={`#send-code-again`}
            className={`link send-code-again`}
            onClick={this.resendCode.bind(this)}>
            Send code again
          </a>
        </div>

        {this.state.codeResent
        	&& <p>We sent you another email with the code!</p>}
      </MaxWidthContent>
    </div>)
  }

  async resendCode(e) {
  	e.preventDefault();

  	try {
	  	const response = await resend2FACode(this.state.user_id)
	  	console.log(response)
  		if (response.success
  			&& true === response.two_factor_auth_code_sent) {
  			this.setState({
  				codeResent: true,
  			}, () => {
  				this.interval = setTimeout(e => this.setState({codeResent: false}), 3000);
  			})
  		}
  	} catch(err) {
  		console.error(err)
      this.props.addError(err)
      this.props.addActivityIndicator('')
  	}
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.props.loginStarted()

    this.props.addActivityIndicator('Verifying your code')

    try {
      const user = await verify2FACode({
      	user_id: this.state.user_id,
      	code: this.state.code,
      })
      if (user && user.id) {
        this.props.onValidation(user)
      }
    } catch(error) {
      console.error(error)
      this.props.addError(error)
    }
    this.props.addActivityIndicator('')
  }
}

export default connect(getStore, {
  storeUser,
  addError,
  addActivityIndicator
})(TwoFactorAuth);
