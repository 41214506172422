import React, { useState } from 'react';

import InputField from '../../../lib/helpers/InputField'

import {Button} from '../../../lib/styles/forms'

import {
	EditAmbientWrapper,
	SaveButtonWrapper
} from './Lights.components'

const EditAmbientLight = (props) => {

	const {color, intensity} = props.light

	const [intenseLevel, setIntenseLevel] = useState( intensity || 1 )
	const [hex, setHex] = useState( color || '#FFFFFF' )

	const saveAmbient = (e) => {
		e.preventDefault();

		if (intenseLevel !== intensity
			|| hex !== color ) {
			// save Ambient Light
			const args = {
				intensity: +intenseLevel,
				color: hex,
			}
			props.onChange('ambient', args )
		}
	}

	return (<EditAmbientWrapper>
		<h4>Ambient Light</h4>

		<InputField
			type={'range'}
			min={1}
			max={30}
			step={1}
			label={`Intensity: ${intenseLevel}`}
			name={'intensity'}
			value={intenseLevel}
			onChange={e => setIntenseLevel(e.target.value)}
		/>

		<InputField
			type={'text'}
			value={hex}
			label={'Color'}
			name={'hex'}
			onChange={e => setHex(e.target.value)}
		/>

		<SaveButtonWrapper>
			<Button
				small
				onClick={saveAmbient}>
				Save Ambient Light
			</Button>
		</SaveButtonWrapper>
	</EditAmbientWrapper>)
}


export default EditAmbientLight
