import React, {Fragment} from 'react';
import { Link } from "react-router-dom";

import api from '../../boxappapi/index'
// import {getCurrentUser} from '../../boxappapi/user_client'

// import UserAvatar from '../../components/UserAvatar/UserAvatar'

import SlideInNav from '../../components/Navigation/SlideInNav/SlideInNav'

import Footer from '../../components/Footer/Footer'

import {LoginLogoutButton} from './LoginLogoutButton'
import { connect } from 'react-redux'
import {
  setCurrentOrg,
  storeWorldsForUser,
  storeAllWorlds,
  storeUser,
  addError,
  addActivityIndicator
} from "../../redux/actions"
import {getStore} from "../../redux/selectors"

import MenuSection from '../../components/Menus/MenuSection'

function UserNav(props) {

  // const [openNav, openMenu] = useNavToggle(false)

  if (!props.user.authenticated) {
    return (<LoginLogoutButton loggedIn={false} />)
  }

  // const openClass = (openNav)
  //   ? 'open'
  //   : ''

  const displayOrgsLinks = () => {
    if (!props.user.currentUser.orgs)
      return null;

    const getOrgUrl = (id) => {
      const __name = getOrgName(id)
      const __url = api.getDynamicUrl(__name)
      return __url
    }

    const getOrgName = (id) => {
      const __org = getOrg(id)
      const __name = __org.path.replace(/\//g, '')
      return __name
    }

    const getOrg = (id) => {
      return props.user.currentUser.orgs[id]
    }

    const orgIds = Object.keys(props.user.currentUser.orgs)
    if (1 < orgIds.length) {
      return (<MenuSection title={'My Organizations'}>
        {orgIds.map((id, i) => {
          const _name = getOrgName(id)
          const _org = api.getOrgbyName(_name, props.user.currentUser.orgs)
          return (<a
            key={i}
            className={`${(_name === api.org)?'active ':''}link`}
            href={getOrgUrl(id)}>
              {'' !== _name
                ? _org.blogname
                : 'BoxApp'}
            </a>)
        })}
      </MenuSection>)
    }

    return null
  }

  return (
    <SlideInNav
      direction={'right'}
    >
    <Fragment>
      <MenuSection title={'My Account'}>
        <Link
          className={'link'}
          to={`/user-profile/${props.user.currentUser.id}`}>
          Edit Profile
        </Link>
        <Link
          className={'link'}
          to={`/logout`}>
          Logout
        </Link>
      </MenuSection>
      {displayOrgsLinks()}
      <MenuSection
        title={`Long Reads`}
      >
        <a
          className="link"
          href={`${api.webRoot}/page/privacy-policy`}
          target={`_blank`}>
          Privacy Policy
        </a>
        <a
          className="link"
          href={`${api.webRoot}/page/terms-conditions`}
          target={`_blank`}>
          Terms & Conditions
        </a>
      </MenuSection>
      <Footer />
    </Fragment>
    </SlideInNav>
  );
}

export default connect(getStore, {
  storeWorldsForUser,
  storeAllWorlds,
  setCurrentOrg,
  storeUser,
  addError,
  addActivityIndicator
})(UserNav);
