import React, { Component } from 'react';

class ActivityIndicator extends Component {

  render() {
    return (
      <div className={'activity-indicator'}>
        <div>
          <p>{this.props.message}</p>
        </div>
      </div>
    )
  }
}

export default ActivityIndicator