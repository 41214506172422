import styled from 'styled-components'

import {Colors} from '../../../lib/styles/styles'

export const EditTTList = styled.div``

export const TTListItem = styled.div`
	cursor: pointer;
	padding: 0.5em 1em;
	border-bottom: 0.0125em solid ${Colors.lightGray};

	&:hover,
	&:active {
		background: ${Colors.white};
	}
`

export const TTListItemName = styled.div``

export const TTListItemGo = styled.a.attrs(props => ({
	href: props.href || '#Go'
}))`

`

export const TTListItemDel = styled.a.attrs(props => ({
	href: props.href || '#Del'
}))`

`

export const TTListEmpty = styled.div`
	padding: 0.5em;
`

export const NewTT = styled.div`
	margin-top: 1em;
`