import React from 'react'

import {
	TTListItem,
	TTListItemName,
} from './styles'

function TooltipItem({tooltip, eraseTT, openTT}) {

	const __onclick = e => {
		e.preventDefault();
		openTT(tooltip)
	}

	// const __ondelete = e => {
	// 	e.preventDefault();
	// 	// eraseTT()
	// }

  return <TTListItem
		onClick={__onclick}
  >
  	<TTListItemName>
  		{tooltip.name}
  	</TTListItemName>

		{/*<TTListItemGo
		>
			Go!
		</TTListItemGo>*/}

		{/*<TTListItemDel
			onClick={__ondelete}
		>
			Remove
		</TTListItemDel>*/}

  </TTListItem>
}

export default TooltipItem