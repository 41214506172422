import styled from 'styled-components'

import {
	FormWrapper
} from '../../lib/styles/layout'

export const LoginWrapper = styled.div`
	position: relative;
	height: 100vh;
	background-image: url(/images/pages/login/login@2x.png);

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: inset 0px 0px 150px #000;
`

export const LoginFormWrapper = styled(FormWrapper)`
	color: #fff;

	h1 {
		color: #fff;
		font-size: 36px;
	}

	.link {
		color: #fff;
		border-color: #fff;
	}

	.InputField {
		input,
		select {
			background-color: transparent !important;
			border: 0;
			border-bottom: 1px solid #fff;
			appearance: none;
			-webkit-appearance: none;
			color: #fff;
			border-radius: 0;
			&::placeholder,
			&::-webkit-placeholder {
				text-transform: uppercase;
				color: #fff;
			}
		}
	}

	button[type="submit"] {
		background: linear-gradient(rgb(63,60,42), rgb(54,66,44));
		border: 0 !important;
		color: #fff;
		min-width: 174px;
	}

	.forgot-password {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.forgot-password,
	.forgot-username {
		font-size: 90%;
	}
`