import React, {Fragment} from 'react';
import { Link } from "react-router-dom";

import ListWorlds from '../../components/World/ListWorlds/ListWorlds'

import { connect } from 'react-redux'
import {getUser} from "../../redux/selectors"
import {storeUser, addError, addActivityIndicator} from "../../redux/actions"

import Header from '../../components/Header'
import BottomNav from '../../components/Navigation/BottomNav'

const LoginSuccess = (props) => {

  return (
    <Fragment>
      <Header
        left
        title={`@${props.currentUser.username}`}
        right={() => (<Link
            className={'link pwp-float-right'}
            to={'/logout'}
          >
            Logout
        </Link>)}
      />

      <ListWorlds
        forceRefresh={true}
        allowSearch={true}
        perPage={12}
        page={1}
        paginationPrefix={'/all-worlds'} />

      <BottomNav />
    </Fragment>
  )
}

export default connect(getUser, {
  storeUser,
  addError,
  addActivityIndicator
})(LoginSuccess);
