import styled from 'styled-components'
import {Colors} from '../../lib/styles/styles'

export const UserStatsWrapper = styled.div`
	.user-avatar {
		display: inline-block;
	}
`

export const UserStats = styled.div`
	display: inline-block;
	margin-left: 2em;

	@media screen and (min-width: 600px) {
		margin-left: 4em;
	}
`

export const Stat = styled.div`
	display: inline-block;
	text-align: center;
	margin: 0.5em 0;
	width: 5.65em;
	white-space: nowrap;
	box-sizing: border-box;

	a {
		text-decoration: none;
	}
	h3 {
		margin: 0;
		color: ${Colors.darkGray};
	}
	h6 {
		color: ${Colors.gray};
	}
`