import React from 'react'
import styled from 'styled-components'

import {
	Colors
} from './styles'

export const H1Wrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	padding-left: 1em;
	padding-right: 1em;
	max-width: 1000px;
	word-break: break-word;
`

// show a page title
export const PageH1 = (props) => {
	return (<H1Wrapper {...props}>
    <H1>{props.children}</H1>
  </H1Wrapper>
  )
}

export const H1 = styled.h1.attrs(props => ({
	fontColor: Colors[ props.color ] || Colors.darkGray
}))`
	color: ${props => props.fontColor};
	word-break: break-word;
`

export const H2 = styled.h2`
	font-size: 1.75em;
	margin-top: 1em;
	margin-bottom: 1em;
	word-break: break-word;
`

export const H3 = styled.h3`
	font-size: 1.5em;
	margin-top: 1em;
	margin-bottom: 1em;
	word-break: break-word;
`

export const H4 = styled.h4`
	font-size: 1.25em;
	font-weight: 700;
	margin-top: 1em;
	margin-bottom: 1em;
	word-break: break-word;
`

export const H5 = styled.h5`
	font-size: 1.175em;
	font-family: Open Sans;
	font-weight: 700;
	margin-top: 1em;
	margin-bottom: 1em;
`

export const H6 = styled.h6`
	font-size: 1.175em;
	font-family: Open Sans;
	font-weight: 700;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
`

export const Paragraph = styled.p`
	text-transform: ${props => props.large ? 'uppercase' : 'none'};
	font-size: ${props => props.large ? '1.5em' : '1em'};
	line-height: 1.4em;
	margin-top: 1em;
	margin-bottom: 1em;
	line-height: 1.45;
	color: rgb(29, 67, 111);

	@media screen and (min-width: 600px) {
		font-size: ${props => props.large ? '2em' : '1em'};
		line-height: 1.75em;
	}
`

export const P = styled(Paragraph)``

export const Italic = styled.i`
	font-style: italic;
`

export const Bold = styled.strong`
	font-weight: 700;
`

export const CenteredText = styled.div`
	text-align: center;
`

export const Spacer = styled.div`
	width: ${props => props.width || '100%'};
	padding-top: ${props => props.height};
	display: ${props => props.inline ? 'inline-block' : 'block'};
`

export const Hero = styled.div`
	position: relative;
	width: 100vw;
	height: ${props => props.small ? '35vh' : '60vh'};
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: -1;
	margin-bottom: -5em;
	margin-left: -2em;

	.gradient {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 65%;
		background: ${props => !props.splash
		? 'linear-gradient(rgba(255, 255, 255, 0.0), 20%, rgba(255, 255, 255, 0.75), 50%, rgba(255, 255, 255, 1));'
		: 'linear-gradient(transparent, white);'};
		z-index: 9;
	}
`

export const HeroBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(${props => props.url});
`

// show a page title
export const PageHero = (props) => {
	return (<Hero {...props}>
    <HeroBackground
     url={props.image} />
    <div className={'gradient'} />
  </Hero>
  )
}
