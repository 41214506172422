import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export default class Application extends Component {
	constructor(props) {
		super(props)

		this.state = {}
	}


	render () {
		return (<Helmet>
			<title>{this.props.title} | BoxApp.io</title>

			<meta property="og:title" content={this.props.title} />
			<meta property="og:description" content={this.props.description} />
			<meta property="og:image" content={this.props.image} />
			<meta property="og:url" content={this.props.url || this.props.location.pathname + this.props.location.search} />
			<meta name="twitter:card" content={this.props.description} />


			<meta property="og:site_name" content="BoxApp.io" />
			<meta name="twitter:image:alt" content="Posted from boxapp.io" />


			<meta property="fb:app_id" content="" />
			<meta name="twitter:site" content="@" />
		</Helmet>);
	}
};