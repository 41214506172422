import React from 'react';

import {
	WelcomeScreenWrapper,
	AppLogo,
	WelcomeOptions,
	OptionButton
} from './styles'

import {
	Gradient,
} from '../../lib/styles/styles'

import {
  MaxWidthContent
} from '../../lib/styles/layout'

const WelcomeScreen = ({onSelection}) => {

	return (
		<WelcomeScreenWrapper>
			<MaxWidthContent
	      width={`800px`}
	    >
				<AppLogo>
					<img
	          src={'/images/logo/app/logo.png'}
	          alt={'BoxApp Logo'} />
				</AppLogo>

				<WelcomeOptions
					bgColor={`rgba(198, 146, 145, 0.4)`}
				>
					<OptionButton
						onClick={() => onSelection('register')}
					>
						<Gradient
							start={'rgb(204, 171, 237), 20%'}
							end={'rgb(193, 128, 112)'}
							height={'100%'}
						/>
						<span>Register</span>
					</OptionButton>

					<OptionButton
						onClick={() => onSelection('login')}
					>
						<span>Login</span>
						<Gradient
							start={'rgb(196, 130, 113), 80%'}
							end={'rgb(249, 186, 168)'}
							height={'100%'}
						/>
					</OptionButton>
				</WelcomeOptions>
			</MaxWidthContent>
		</WelcomeScreenWrapper>
	)
}

export default WelcomeScreen