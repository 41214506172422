import React, {useState} from 'react';
import EditObject from '../EditObject/EditObject'
import api from '../../../boxappapi'

import {
	Button
} from '../../../lib/styles/forms'

import {P} from '../../../lib/styles/typography'

import {
  TTListEmpty
} from '../Tooltips/styles'

import ObjectItem from './ObjectItem'

const ListObjects = (props) => {

	const [activeObject, setActiveObject] = useState()

	const onSave = () => {
		// setActiveObject(null)
		props.reloadObjects()
	}

	const addNew = () => {
		const newObject = {
      ID: 0, // id must be zero in order to create a new one
      title: 'New Object',
      world_id: props.worldID,
      position: '0,0,-2',
      size: '1,1,1',
      rotate: '0,0,0',
      lit: 1,
    }

		setActiveObject(newObject)
	}

	if (activeObject) {
		return <EditObject
			object={activeObject}
			onSave={onSave}
			updatePreview={props.updatePreview}
			closeNB={() => setActiveObject(null)}
		/>
	}

	return (<div>
		{props.objects && 0 < props.objects.length
			? props.objects.map( (obj, idx) => {
				return (<ObjectItem
						key={idx}
						object={obj}
						openTT={() => setActiveObject(obj)}
					/>)
				})
			: <TTListEmpty>
          <P>You have not added any 3D ojects yet.</P>
        </TTListEmpty>}

		<div className={'new-object-button'}>
      <Button
        small
        onClick={addNew}
      >
      	+ 3D Object
      </Button>
    </div>
    <p><i>This feature is in beta. To learn more about 3D objects,
    	click <a
    	target={'_blank'}
    	href={`${api.webRoot}/page/how-to-insert-position-and-size-3d-objects/`}
    	className={'link'}>here</a>.</i></p>
	</div>)
}


export default ListObjects