import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import api from '../../../boxappapi/index'
import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"

import AdminPage from "../../../components/AdminPage/AdminPage"
import {PageH1} from "../../../lib/styles/typography"
import {PageContent} from "../../../lib/styles/layout"
import OrgSettingsForm from './OrgSettingsForm'
import OrgSubNav from '../SubNav/OrgSubNav'

import BottomNav from "../../../components/Navigation/BottomNav"

class OrgSettingsPage extends Component {

  constructor(props) {
  	super(props)

  	this.state = {}
  }

  render() {

    const _org = this.props.user.currentUser
      ? api.getOrgbyName(api.org, this.props.user.currentUser.orgs)
      : null
    const orgName = _org ? _org.blogname : ''

    return (<AdminPage isOrg isAdmin>
      <PageContent>
      <div className={`Edit-Organization`}>
        <OrgSubNav location={this.props.location} />
      	<PageH1>{orgName}</PageH1>
        <OrgSettingsForm />
      </div>
      </PageContent>
      <BottomNav />
    </AdminPage>)
  }
}

export default connect(getStore, {})(OrgSettingsPage);
