import React from 'react';
import { Link } from "react-router-dom";

import { connect } from 'react-redux'
import {getStore} from "../../redux/selectors"

import UserAvatar from '../UserAvatar'



import {
  HeaderContainer,
  HeaderTitle,
  LeftNav,
  RightNav
} from './styles'

const HomeLink = ({history}) => {
  const __handleClick = (e) => {
    if (history) {
      e.preventDefault();
      history.goBack()
    }
  }
  return (
    <Link
      onClick={__handleClick}
      className="link home-btn"
      to={'/'}
    >
      <img alt={`back button`} src={'/images/icons/back.svg'} />
    </Link>
  )
}

const Header = ({left, right, title, children, user, background, border, history}) => {

  return (
    <HeaderContainer
      background={background}
    >

        {right
          && <RightNav>
          {'function' === typeof right
            ? right()
            : <UserAvatar
                link={true}
                userObj={user.currentUser}
                size={24}
              />}
          </RightNav>}

        {left
          && <LeftNav>
            {'function' === typeof left
              ? left()
              : <HomeLink
                history={history}
              />}
          </LeftNav>}

        {title
          && <HeaderTitle>
            {'function' === typeof title
              ? title()
              : title}
          </HeaderTitle>}

      {children}
    </HeaderContainer>
  );
}

export default connect(getStore, {})(Header);