const initialState = {
  notifications: [],
  notificationHistory: [],
}

const updateErrors = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ERROR": {
		  const { error } = action.payload
      const newState = {
        notifications: [...state.notifications, {
          type: error.type || 'error',
          code: error.code,
          message: error.message,
        }]
      }
      return Object.assign({}, state, newState);
    }

    case "REMOVE_ERROR": {
      const { key } = action.payload
      const errorToDelete = state.notifications.splice(key, 1)
      const newState = {
        notificationHistory: [...state.notificationHistory, errorToDelete]
      }
      return Object.assign({}, state, newState);
    }

    default: {
      return state;
    }
  }
};

export default updateErrors;
