import React, {useState, Fragment} from 'react'
// components
import EditNavButton from './EditNavButton'
import TooltipItem from '../Tooltips/TooltipItem'

import {
	NewTT
} from '../Tooltips/styles'

import {
	Button
} from '../../../lib/styles/forms'

function ListNavButtons({
	navButtons,
	worldID,
	user,
	deleteNavButton,
	saveNavButton,
	newNavButton
}) {

	const [activeNavButton, setActiveNavButton] = useState(null)

	const __closeNavButton = () => setActiveNavButton(null)

	const __openNavButton = (tt) => setActiveNavButton(tt)

	const __deleteNavButton = (ttID) => {
		deleteNavButton(ttID)
		setActiveNavButton(null)
	}

	const __createNavButton = async (e) => {
    e.preventDefault()
    // compose a new tooltip
    const __newNavButton = {
      id: 0, // id must be zero in order to create a new one
      name: 'New Nav Button',
      location_id: 0,
      world_id: worldID,
    }
    // save it
    const newNB = await saveNavButton(__newNavButton)
    setActiveNavButton(newNB)
  }

  const __saveNavButton = (newNB) => {
  	saveNavButton(newNB)
  	setActiveNavButton(null)
  }

	if (activeNavButton) {
    return <EditNavButton
      navButton={activeNavButton}
      worldID={worldID}
      user={user}
      onDelete={__deleteNavButton}
      onSave={__saveNavButton}
      closeNavButton={__closeNavButton}
    />
	}

	return <Fragment>

		{navButtons
      && navButtons.map((tt, i) => {
	    return <TooltipItem
	      tooltip={tt}
	      key={i}
	      openTT={__openNavButton} />
	  })}

	  <NewTT>
      <Button
      	small
        onClick={__createNavButton}
      >
      	+ Nav Button
      </Button>
    </NewTT>

	</Fragment>

}

export default ListNavButtons
