import styled from 'styled-components'
import {Colors} from '../../../lib/styles/styles'

export const FollowerCard = styled.div`
	border-bottom: 0.0125em solid ${Colors.gray};
	margin-bottom: 0.5em;
	padding: 0.5em;

	h3,
	.user-avatar {
		display: inline-block;
		vertical-align: middle;
	}

	h3 {
		margin: 0 0 0 1em;
	}
`