import styled from 'styled-components'

import {
	FormWrapper
} from '../../lib/styles/layout'

import {
	Button
} from '../../lib/styles/forms'

export const WelcomeScreenWrapper = styled.div`
	position: relative;
	height: 100vh;
	background-image: url(/images/pages/login/welcome/welcome.png);

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const AppLogo = styled.div`
	padding-top: 22%;
	text-align: center;
`

export const WelcomeOptions = styled(FormWrapper)`

	h1 {
		color: #fff;
	}
`

export const OptionButton = styled(Button)`
	display: block;
	margin: 0.625em auto;
	position: relative;
	padding: 0.5em 2em;
	min-width: 174px;
	box-sizing: border-box;
	color: #fff;
	text-transform: uppercase;
	border: 0;

	> div {
		z-index: 0;
	}

	> span {
		position: relative;
		z-index: 2;
	}
`