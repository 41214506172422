import React, { Component } from 'react';
import SubNav from '../../../components/Navigation/SubNav'
import Header from '../../../components/Header'

class OrgSubNav extends Component {

  constructor(props) {
  	super(props)

  	this.state = {}
  }

  render() {
    return (<Header>
      <SubNav
        location={this.props.location}
  			links={[
          {
            text: 'Organization',
    				to: '/org/edit-organization',
          },
  				{
            text: 'Users',
  				  to: '/org/manage-users',
          },
  				{
            text: 'Settings',
  				  to: '/org/settings',
          },
        ]}
      />
    </Header>)
  }
}

export default OrgSubNav;