import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
// import ReactPlayer from 'react-player'
// redux
import {
  // storeWorldsForUser,
  addActivityIndicator,
  addError,
} from "../../../redux/actions"
import { connect } from 'react-redux'
// components
import api from '../../../boxappapi/index'
import WorldAPIClient from '../../../boxappapi/world_client'

// import MediaPreview from '../../../lib/helpers/MediaPreview'
// import ExpandableContainer from '../../../lib/helpers/ExpandableContainer'
import EditTooltips from '../../../components/World/Tooltips/EditTooltips'
import EditNavButtons from '../../../components/World/NavButtons/EditNavButtons'
import EditTaxonomies from '../../../components/World/Taxonomies/EditTaxonomies'
// import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop';

import AdvancedSettings from '../../../components/World/AdvancedSettings/AdvancedSettings'
import InsertObjects from '../../../components/World/InsertObjects/InsertObjects'
import EditLights from '../../../components/World/Lights/EditLights'

import SlidingTreeNav from '../../../components/Navigation/SlidingTreeNav'
import NavItem from '../../../components/Navigation/SlidingTreeNav/NavItem'



// forms
import WorldInfo from '../../../components/forms/World/WorldInfo'


class EditWorldForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      world: null,
      id: 0,
      name: '',
      description: '',
      pano: null,
      panoVideo: null,
      status: '',

      worldDeleted: false,
      open: false,
    }

    this.resetKeyUp = false;
    this.mediaInput = null
    this.worldUrl = ''
  }

  componentWillMount() {
    if (this.props.world) {
      this.updateProps()
    }
  }

  componentWillUnmount() {
    this.setState({world: null})
  }

  componentDidUpdate(prevProps, prevState) {
    //   // this.updateProps()

    // console.log(prevProps.world, this.props.world, prevState.world, this.state.world)
    // if (this.props.world.ID !== this.state.world.ID) {
    //   console.log('Update ran')
    // }
  }

  render() {
    // if (this.state.worldDeleted) {
    //   return (<Redirect to={{
    //     pathname: '/',
    //     state: { refresh: true, }
    //   }} />)
    // }

    if (!this.state.world) {
      return null;
    }

    return (
      <div className={'new-world-form'}>

        <SlidingTreeNav>

          <NavItem
            title={'World Info'}>

            <WorldInfo
              currentWorld={this.state.world}

              updatePreview={this.props.updatePreview} />
          </NavItem>

          <NavItem
            title={'Tooltips'}>

            <EditTooltips
              tooltips={this.state.world.tooltips}
              worldID={this.state.world.ID}
              user={this.props.user}
              updatePreview={this.props.updatePreview} />
          </NavItem>

          <NavItem
            title={'Nav Buttons'}>

            <EditNavButtons
              navButtons={this.state.world.nav_buttons}
              worldID={this.state.world.ID}
              user={this.props.user}
              updatePreview={this.props.updatePreview} />
          </NavItem>

          <NavItem
            title={'3D Objects'}>

            <InsertObjects
              worldID={this.state.world.ID}
              user={this.props.user}
              updatePreview={this.props.updatePreview} />
          </NavItem>

          <NavItem
            title={'Lights'}>

            <EditLights
              worldID={this.state.world.ID}
              user={this.props.user}
              updatePreview={this.props.updatePreview} />
          </NavItem>

          <NavItem
            title={'Categories'}>

            <EditTaxonomies
              worldID={this.state.world.ID}
              user={this.props.user}
              updateWorld={this.updateWorld.bind(this)} />
          </NavItem>

          {api.isOrg()
            && <NavItem
              title={'Advanced Settings'}>
              <AdvancedSettings
                worldID={this.state.world.ID}
                settings={this.state.world.advanced_settings}
                updatePreview={this.props.updatePreview} />
            </NavItem>}

          <div className={`delete-world pwp-align-center`}>
            <a
              href={'#delete_world'}
              className={'link warning'}
              onClick={this.deleteWorld.bind(this)}
            >
              Delete World
            </a>
          </div>
        </SlidingTreeNav>

      </div>
    );
  }

  deleteVideo(e) {
    e.preventDefault();
    this.setState({panoVideo: ''})
    this.saveWorld();
  }

  checkWhenToSave() {

    if (this.resetKeyUp) {
      // this prevents multiple API calls when the user is typing
      return
    }

    this.resetKeyUp = true;
    // use this state to indicate any loading animation, etc
    this.timeInterval = setTimeout(this.saveWorld.bind(this), 3000);
  }

  updateProps() {
    this.setState({
      world: this.props.world,
      status: ('' !== this.state.status) ? this.state.status : this.props.world.post_status,
      name: this.props.world.post_title,
      description: this.props.world.post_content,
    })

    this.worldUrl = api.getVRURL(this.props.world.ID)
  }

  updateState(input) {
    const { target } = input;
    const newState = {}

    if ('file' === target.type
      && 0 < target.files.length) {
      newState[target.name] = target.files[0]
      if(!this.resetKeyUp) this.checkWhenToSave()
    } else
    if (target.options) {
      for (let i in target.options) {
        if (true === target.options[i].selected) {
          newState[target.name] = target.options[i].value
          if(!this.resetKeyUp) this.checkWhenToSave()
          break;
        }
      }
    } else {
      newState[target.name] = target.value
    }
    this.setState(newState)
  }

  displayError(err) {
    console.error(err.message)
    this.props.addError(err)
    this.props.addActivityIndicator('')
  }

  updateWorld(args) {
    args = args || {}

    const _args = Object.assign({}, {
      name: this.state.name,
      description: this.state.description,
    }, args)

    this.saveWorld(_args, true)
  }

  async saveWorld(data, silent) {
    silent = silent || false
    this.props.addActivityIndicator('Updating world.')

    if (this.props.user
      && this.props.user.token
      && (0 < this.props.user.id)) {

      try {
        data = data || {
          user_id: this.props.user.id,
          status: ('' !== this.state.status) ? this.state.status : this.props.world.post_status,
          name: this.state.name,
          description: this.state.description,
          pano_media: this.state.pano,
          pano_video: this.state.panoVideo,
        }

        const API = new WorldAPIClient(this.props.user.token);
        const response = await API.updateWorld(this.state.world.ID, data)

        if (response.data
          && true === response.data.success) {
          if (!silent) this.props.updatePreview()
          this.props.addActivityIndicator('')
        }
      } catch (err) {
        this.displayError(err)
      }

      this.resetKeyUp = false
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.props.addActivityIndicator('Updating world.')

    if (this.props.user
      && this.props.user.token
      && (0 < this.props.user.id)) {

      try {
        const data = {
          user_id: this.props.user.id,
          status: ('' !== this.state.status) ? this.state.status : this.props.world.post_status,
          name: this.state.name,
          description: this.state.description,
          pano_media: this.state.pano,
          pano_video: this.state.panoVideo,
        }

        const API = new WorldAPIClient(this.props.user.token);
        const response = await API.updateWorld(this.state.world.ID, data)

        if (response.data
          && true === response.data.success) {
          this.props.updatePreview()
          this.props.addActivityIndicator('')
        }
      } catch (err) {
        this.displayError(err)
      }
    }
  }

  async deleteWorld(e) {
    e.preventDefault();

    const confirm = window.confirm('Are you sure you want to delete this world?');

    if (!confirm)
      return;

    this.props.addActivityIndicator('Deleting world.')

    try {
      const API = new WorldAPIClient(this.props.user.token);
      const response = await API.deleteWorld(this.state.world.ID)
      if (response.data
        && true === response.data.success) {
        // this.setState({worldDeleted: true})
      this.props.goBack()
        this.props.addActivityIndicator('')
      }
    } catch (err) {
      this.displayError(err)
    }
  }
}

export default connect(null, {
  addActivityIndicator,
  addError
})(EditWorldForm);
