import styled from 'styled-components';

export const AvatarWrapper = styled.div`
	position: relative;
  display: block;
  vertical-align: middle;
  overflow: hidden;

  a {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1em;
    color: #fff;
    font-size: 1.25em;
  }
`;

export const AvatarIcon = styled.div`
  min-width: 1.75em;
  min-height: 1.75em;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50%;
  border: 1px solid #444;
  background-color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

	width: ${props => props.size}px;
	height: ${props => props.size}px;
	background-image: url(${props => props.imgSrc});
`;