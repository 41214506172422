import styled from 'styled-components'

export const Row = styled.div`
	margin-left: -1%;
	margin-right: -1%;

	&:after {
		content: '';
		clear: both;
	}
`

export const Welcome = styled.div`
	text-align: center;
	color: inherit;

	img {
		max-width: 370px;
		@media screen and (max-width: 750px) {
			max-width: 155px;
		}
	}
`

const getColumnWidth = (size) => {
	size = size || 2
	return `${((100 / size) - 2)}%`
}

export const Column = styled.div`
	float: left;
	margin-left: 1%;
	margin-right: 1%;
	width: ${props => getColumnWidth(props.size)};

	@media screen and (max-width: 750px) {
		width: 98%;
		float: none;
	}
`

export const Title = styled.div`
	margin-top: 1em;;
	text-transform: uppercase;
	color: inherit;
	font-size: 40px;

	@media screen and (max-width: 750px) {
		font-size: 26px;
	}
`

export const SuccessMessage = styled.div`
	text-align: center;
`

export const Intro = styled.div`
	text-align: center;
	color: inherit;

	p {
		font-size: 1em;
	}

	a {
		color: inherit;
		border-color: inherit;
	}
`