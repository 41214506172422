import React, {useState, useEffect, Fragment} from 'react';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"

import {useNavToggle} from '../../../lib/hooks/NavHooks'

import {
  SlideInNavWrapper,
  SlideInNavBtn,
  SlideInNavCont,
} from './SlideInNav.styles'

/*
NOT BEING USED YET
 */
function SlideInNav(props) {

  useEffect(() => setNavheight( getNavHeight() ))

  const {
    toggleBtn,
    direction,
    size,
  } = props

  const [openNav, openMenu] = useNavToggle(false)

  const [navHeight, setNavheight] = useState('100vh')

  const openClass = (openNav) ? 'open' : ''

  const getNavHeight = () => {
    const height = document.getElementById(`header`)
    const netHeight = height && (window.innerHeight - height.offsetHeight)
    return netHeight || '100vh'
  }

  window.onresize = (e) => setNavheight( getNavHeight() )

  const checkToClose = () => {
    if(!props.preventAutoClose) {
      openMenu()
    }
  }

  return (<SlideInNavWrapper direction={direction}>
    <SlideInNavBtn
      direction={direction}
      className={`toggle-btn ${openClass} ${direction}`}
      onClick={openMenu}>
      {toggleBtn && 'function' === typeof toggleBtn
        ? toggleBtn(openNav)
        : <Fragment>
          <span className={`menu-bar top`}></span>
          {/*<span className={`menu-bar middle`}></span>*/}
          <span className={`menu-bar bottom`}></span>
        </Fragment>}
    </SlideInNavBtn>
    <SlideInNavCont
      direction={direction}
      size={size}
      height={navHeight}
      className={`nav-content ${openClass} ${direction}`}
      onClick={checkToClose}>
      {props.children}
    </SlideInNavCont>
  </SlideInNavWrapper>);
}

export default connect(getStore, {})(SlideInNav);
