import React from 'react';

import {
	Title,
	Welcome,
	Intro
} from './RegistrationForm.components'

const RegistrationFormSuccessMessage = (props) => {

	const {goToLogin} = props

	const __goToLogin = e => {
		e.preventDefault()
		goToLogin()
	}

	return (<>
		<Welcome>
			<Title>
				Welcome!
			</Title>
		</Welcome>
		<Intro>
			<p>
				Thank you for joining the BoxApp community.
				 We're excited you're here!
			</p>
			<p>
				In just a few minutes, you’ll receive an email
				 with some helpful tips on getting started.
				  If you don’t receive it, check to make sure
				   we didn’t end up in the dreaded SPAM folder.
			</p>
			{/*<p>
				We’re here for you, so just let us know if
				 you have any questions or feedback along the way.
			</p>*/}
			<p>
				Cheers to creating and sharing 360º images and videos!
			</p>
			<p>
				<a
					className={'link'}
					href={'#goToLogin'}
					onClick={__goToLogin}
				>Login</a>
				&nbsp;to begin uploading 360 images or videos.
			</p>
		</Intro>
	</>)
}

export default RegistrationFormSuccessMessage;
