import styled from 'styled-components'

// import {
// 	Row,
// 	Column
// } from '../../../lib/styles/layout'

export const EditAmbientWrapper = styled.div`
	display: block;
	padding: 1em;
	margin-bottom: 2em;
	border-bottom: 1px solid #ccc;

	h4 {
		margin-top: 0;
	}
`

export const SaveButtonWrapper = styled.div`
	display: block;
	text-align: right;
`