import React from 'react'
import styled from 'styled-components'

export const Row = styled.div`
	display: block;
	box-sizing: border-box;
	margin: 0 -${props => +props.gutter}%;

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	${props => props.center ? 'text-align: center;' : ''}
`

export const Column = styled.div`
	display: block;
	box-sizing: border-box;
	float: none;
	margin: ${props => +props.gutter}%;
	width: ${props => 100 - (+props.gutter * 2)}%;

		width: ${props => ((100 / props.size) - (+props.gutter * 2))}%;
		float: left;
	@media screen and (min-width: 600px) {

	}
`

export const ShowOnTablet = styled.div`
	display: none;
	@media screen and (min-width: 600px) {
		display: inline-block;
	}
`

export const HideOnTablet = styled.div`
	@media screen and (min-width: 600px) {
		display: none;
	}
`

export const AlignRight = styled.div`
	text-align: right;
`

export const Spacer = styled.div`
	width: ${props => props.width || '100%'};
	padding-top: ${props => props.height || '1px'};
	display: ${props => props.inline ? 'inline-block' : 'block'};
`

export const Hero = styled.div`
	position: relative;
	width: 100vw;
	height: ${props => props.small ? '35vh' : '60vh'};
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: -1;
	margin-bottom: -5em;
	margin-left: -2em;

	.gradient {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 65%;
		background: ${props => !props.splash
		? 'linear-gradient(rgba(255, 255, 255, 0.0), 20%, rgba(255, 255, 255, 0.75), 50%, rgba(255, 255, 255, 1));'
		: 'linear-gradient(transparent, white);'};
		z-index: 9;
	}
`

export const HeroBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(${props => props.url});
`

export const FullBackground = styled.div`
	position: relative;
	height: 100%;
	background-image: url(${props => props.image || ''});

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const FormWrapper = styled.div`
	${props => 'top' === props.position
		? 'top: 0;'
		: 'bottom: 0;'}
	background-color: ${props => props.bgColor};
	padding: 40px 30px;
	position: absolute;
	left: 10%;
	width: 80%;
	text-align: center;
	box-sizing: border-box;
`

export const MaxWidthContent = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: block;
	margin:  0 auto;
	position: relative;
	height: ${props => props.height || '100%'};
	max-width: ${props => props.width || '100%'};
	padding: ${props => props.padding || '0'};
`

export const PageContent = styled(MaxWidthContent)`
	margin-left: auto;
	margin-right: auto;
	padding: 2em;
	max-width: 800px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
`

// show a page title
export const PageHero = (props) => {
	return (<Hero {...props}>
    <HeroBackground
     url={props.image} />
    <div className={'gradient'} />
  </Hero>
  )
}