import React, { useState } from 'react';

import ObjectsAPIClient from '../../../boxappapi/objects_client';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import {
	DeleteButton,
	Button
} from '../../../lib/styles/forms'

import InputField from '../../../lib/helpers/InputField'

import {
  AlignRight,
  Spacer
} from '../../../lib/styles/layout'

import {
	XYZFields,
	EditObjectWrapper,
	// EditObjectButtons
} from './EditObject.components'

import ObjectFiles from './ObjectFiles'

const EditObject = (props) => {

	const [objFiles, setObjFiles] = useState(props.object.obj_files || [])

	const [ID, setID] = useState(props.object.ID)

	const [title, setTitle] = useState(props.object.title || '')
	const [position, setPosition] = useState(props.object.position)
	const [rotate, setRotate] = useState(props.object.rotate)
	const [size, setSize] = useState(props.object.size)
	const [lit, setLit] = useState(props.object.lit)

	const [removeObject, setRemoveObject] = useState( false )

	const saveFiles = (files) => {
		setObjFiles( files )
	}

	const saveObject = async (e) => {
		e.preventDefault();

		props.addActivityIndicator('Saving object..')

		const newObject = {
			title: title,
			position: position,
			size: size,
			rotate: rotate,
			lit: lit,
			world_id: props.object.world_id
		}

		for (var i = 0; i < objFiles.length; i++) {
			newObject[`objFile_${i}`] = objFiles[i]
		}

		try {
			const client = new ObjectsAPIClient(props.user.token)
			const response = await client.updateObject(ID, newObject)

			if ( true === response.success ) {
				setID( response.id )
				setTitle( response.object.title )
				setPosition( response.object.position )
				setSize( response.object.size )
				setRotate( response.object.rotate )
				setLit( response.object.lit )

				setObjFiles( response.object.obj_files )

				props.addActivityIndicator('')
				props.updatePreview()
				props.onSave()
			}

		} catch(err) {
			console.error(err)
			props.addError(err)
		}
	}

	const deleteObject = async (e) => {
		e.preventDefault();

		props.addActivityIndicator('Removing object..')

		try {
			const client = new ObjectsAPIClient(props.user.token)
			const response = await client.deleteObject({ id: ID })

			if ( true === response.success ) {
				setRemoveObject( true )
			}

			props.addActivityIndicator('')
			props.updatePreview()

			props.onSave()

		} catch(err) {
			console.error(err)
			props.addError(err)
		}
	}

	// remove when deleted
	if (true === removeObject) {
		return null;
	}

	return (<EditObjectWrapper>

		<AlignRight>
        <i>
          <a href={'#close'}
            onClick={e => {
              e.preventDefault();
              props.closeNB()
            }}
          >
            Close this Object
          </a>
        </i>
      </AlignRight>

		<h4>{'' !== title ? title : 'Untitled'}</h4>

		<p>Files</p>
		<ObjectFiles
			allowedType={['obj', 'mtl', 'jpeg', 'jpg', 'png', 'gif']}
			currentFiles={objFiles}
			saveFiles={saveFiles} />

		<InputField
			type={'text'}
			name={'title'}
			placeholder={'Title'}
			value={title}
			onChange={e => setTitle(e.target.value)} />

		<XYZFields
			title={'Position'}
			value={position.split(',')}
			onChange={setPosition} />

		<XYZFields
			title={'Rotate'}
			value={rotate.split(',')}
			onChange={setRotate} />

		<XYZFields
			title={'Size'}
			value={size.split(',')}
			onChange={setSize} />

		<InputField
			type={'checkbox'}
			name={'lit'}
			label={'Ambient light should affect this object.'}
			value={lit}
			checked={lit}
			onChange={e => setLit(e.target.checked ? 1 : 0)} />

		<AlignRight>
			{undefined !== typeof ID && 0 < ID
				&& <>
				<DeleteButton
					small
					tabIndex={-1}
	        onClick={deleteObject}
	       >
	        Delete
	      </DeleteButton>
	      <Spacer
          width={`1em`}
          inline
        />
	    </>}
      <Button
      	small
        onClick={saveObject}>
        Save
      </Button>
		</AlignRight>
	</EditObjectWrapper>)
}


export default connect(getStore, {
  addActivityIndicator,
  addError,
})(EditObject);
