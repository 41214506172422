import React, { Component } from 'react';

import WorldUploader from '../../../components/World/WorldUploader/WorldUploader'

import DragNDrop from '../../../components/forms/DragNDrop/DragNDrop';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  setWorldToEdit,
  addActivityIndicator,
  addError
} from "../../../redux/actions"

class NewWorldForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      panos: null,
    }
  }

  render() {

    return (
      <div className="Multiple-Worlds-Form">

        <form
          className={'multiple-worlds-form'}>

          <DragNDrop
            onDrop={files => {
              if (this.state.panos && 0 < this.state.panos.length) {
                const __panos = [...this.state.panos, ...files]
                this.setState({
                  panos: __panos
                })
              } else {
                this.setState({
                  panos: files
                })
              }
            }} />
          <span><i><b>Compatible formats:</b> jpg, png, jpeg | mp4, mov</i></span>
        </form>


        <div>
          <h3>Upload Status</h3>

          {(!this.state.panos || 0 === this.state.panos.length)
            ? <p>
              No uploads in the queue to display.
            </p>
            : <p>
              Uploads may take a while, please keep this window open to avoid interruptions.
            </p>}

          {this.state.panos
            && 0 < this.state.panos.length
            && <div>
              {this.displayUploaders(this.state.panos)}
            </div>}
        </div>
      </div>
    );
  }

  displayUploaders(panos) {

    let _queue = []
    for (const idx in panos) {

      if ('File' !== panos[idx].constructor.name) {
        continue
      }

      const _item = panos[idx]
      const _pano = <WorldUploader
        key={idx}
        pano={_item} />

      _queue = [..._queue, _pano]
    }
    return _queue
  }
}

export default connect(getStore, {
  setWorldToEdit,
  addActivityIndicator,
  addError,
})(NewWorldForm);
