import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux'
import {resetUser} from "../../redux/actions"

import BoxappCookie from '../../BoxappCookie'

class Logout extends Component {

  componentWillMount() {
    // remove user
    BoxappCookie.remove('boxappUser')

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage( '__removeBoxAppCookie' )
    }

    this.props.resetUser()
  }

  render() {
    return <Redirect to='/login' />
  }
}

export default connect(null, {
  resetUser,
})(Logout);
