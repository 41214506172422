import React from 'react';
import { Link } from "react-router-dom";

export const LoginLogoutButton = (props) => {
  return (<div
    className={`login-button`}>
    <Link
      to={(props.loggedIn) ? `/logout` : `/login`}
      className={`link login-link`}>
      {(props.loggedIn) ? `Logout` : `Login`}
    </Link>
  </div>)
}