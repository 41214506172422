import React from 'react';

import {
	NavItemWrapper,
	NavItemTitle,
	// NavItemArrow
} from './SlidingTreeNav.components'

const NavItem = (props) => {

	const {
		title,
		openNav,
	} = props;

	return (<NavItemWrapper onClick={e => {
		e.preventDefault()
		openNav && 'function' === typeof openNav
			&& openNav(props)
	}}>
		{/*<NavItemArrow />*/}
		<NavItemTitle>
			{title}
		</NavItemTitle>
	</NavItemWrapper>)
}


export default NavItem