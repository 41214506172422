import{ useState, useLayoutEffect } from 'react';

function useWindowResize() {

	const [width, setWidth] = useState(window.innerWidth || 0);
	const [pause, setPause] = useState(false)

	useLayoutEffect(() => {

		const handleChange = (e) => {
			if (pause) {
				return null;
			}

			setWidth(e.target.innerWidth)
		}

		window.addEventListener('resize', handleChange)

		return () => window.removeEventListener('resize', handleChange)
	})

	const pauseResize = (bool) => setPause(bool)

  return [width, pauseResize]
}

export default useWindowResize