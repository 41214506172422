import styled from 'styled-components'

import {Colors} from '../../../lib/styles/styles'

export const NavContainer = styled.div`
	display: block;
	position: relative;
  height: 100%;
  box-sizing: border-box;
`

export const NavHeader = styled.div`
	padding: 0.25em 0.5em;
	min-height: 45px;
	box-sizing: border-box;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 99;
`

export const HeaderTitle = styled.div`
	text-align: center;
	h3 {
		margin: 0 !important;
	}
`

export const NavContent = styled.div`
	display: block;
	overflow: auto;
	box-sizing: border-box;
	padding: 1em;
	height: 80vh;
`

export const Arrow = styled.div`
	display: inline-block;
	position: relative;
	width: 2em;
	height: 1.75em;
	cursor: pointer;

	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`

export const BackArrow = styled(Arrow)`
	position: absolute;
	top: 0.75em;
	left: 0.5em;
	background-image: url(/images/icons/back-chevron.png);
`

export const NavItemWrapper = styled.div`
	padding: 1.5em 1em;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
`

export const NavItemTitle = styled.h3`
	margin: 0;
	display: inline-block;
	border-bottom: 1px solid #444;
	color: #444;
`

export const NavItemArrow = styled(Arrow)`
	float: right;
	background-image: url(/images/icons/forward-chevron.png);
`


// export const NavItemWrapper = styled.div`

// `
//


export const BreadCrumbsWrapper = styled.div`
	font-size: 75%;
	padding: 1em;
`

export const BreadCrumbWrapper = styled.div`
	display: inline-block;
	vertical-align: middle
`
export const BreadCrumbTitle = styled.h4`
	margin: 0;
	line-height: 1;
	color: ${Colors.gray};
`
export const BreadCrumbArrow = styled(Arrow)`
	float: right;
`