export const getStore = (store, props) => {
	// console.log(store)
	// console.log(props)
	return {...store};
}

export const getUser = (store, props) => {
	// console.log(store)
	// console.log(props)
	return {...store.user};
}