import React, {useState, useEffect} from "react"
import api from './boxappapi/index'
import {getCurrentUser} from './boxappapi/user_client'
import Router from './Router_Index';
import LoadingIndicator from './lib/helpers/LoadingIndicator'
import BoxappCookie from './BoxappCookie'

// redux
import { connect } from 'react-redux'
import {storeUser, addError, addActivityIndicator} from "./redux/actions"
import {getStore} from "./redux/selectors"

const AppPreLoader = (props) => {

	const _initialMsg = props.initialMsg || 'Loading..'

	const [loading, setLoading] = useState(true)
	const [loadingMsg, setLoadingMsg] = useState(_initialMsg)
	const [userCookie, setUserCookie] = useState(BoxappCookie.get('boxappUser'))

	const askRNForCookie = () => {
    // save the user Cookie
    const _handleRNMessage = (msg) => {
      if (msg.origin === api.appRoot) {
        msg.data
          && '' !== msg.data
          && setUserCookie(JSON.parse(msg.data))
        setLoading(false)
      }
    }
    // bind the event
    window.addEventListener('message', _handleRNMessage)
    // ask device for Cookie
    window.ReactNativeWebView.postMessage( '__checkBoxAppCookie' )
  }

  const initializeAPI = () => {
    const domain = window.location.host.split('.')
    let subdomain = ''
    // set the subdomain
    if ('dev' !== api.ENV && 'app' !== domain[0]) {
      subdomain = domain[0]
    }
    // initialize API
    api.init(subdomain)
  }

  const loadUserFromCookie = async () => {
  	setLoadingMsg('Logging you in..')
  	const {token} = userCookie
    try {
      // authenticate the user
      const user = await getCurrentUser(token)
      if (user) {
        BoxappCookie.saveUserCookie(userCookie)
        props.storeUser(Object.assign({}, {token, id: user.id}, {
          currentUser: user, // return a temporary user w/ limited info
          authenticated: true,
          isAdmin: user.isAdmin,
        }))
      }
      setLoading(false)
    } catch(err) {
    	setLoadingMsg(`Oops! Could not log you in. Let's try this again.`)
    	setTimeout(() => setLoading(false), 2000)
    }
  }

  const initializeApp = () => {
    initializeAPI()
    if (userCookie
    	&& userCookie.token) {
    	loadUserFromCookie()
    } else
    // check if we are in React Native App
    if (!userCookie && window.ReactNativeWebView) {
      // we do not have a cookie,
      // but we are in React Native App.
      // Let's check AsyncStorage for a cookie
      askRNForCookie()
    } else {
    	setLoading(false)
    }
  }

  useEffect(initializeApp, [])

  if (!loading) {
  	return <Router userCookie={userCookie} />
  }

	return (
		<LoadingIndicator message={loadingMsg} />
	)
}

export default connect(getStore, {storeUser, addError, addActivityIndicator})(AppPreLoader);