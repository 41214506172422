import { useState } from 'react';

export function useNavToggle(open) {
	const [openNav, setOpenNav] = useState(open);

  // const bindClickToClose = () => {
  //   document.body.addEventListener( 'click', unbindClickToClose)
  // }

  // const unbindClickToClose = (e) => {
  //   setOpenNav(false)
  // }

  const openMenu = (e) => {
    // e.stopPropagation();
    // e.preventDefault();

    if (!openNav) {
      setOpenNav(true)
      // bindClickToClose()
    } else {
      setOpenNav(false)
      // unbindClickToClose()
    }

    setOpenNav(!openNav)
  }

  return [openNav, openMenu]
}