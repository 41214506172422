import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
// api
import api from '../../boxappapi'
import {getPublicUser, followUser} from '../../boxappapi/user_client'
// redux
import { connect } from 'react-redux'
import {addError, addActivityIndicator} from "../../redux/actions"
import {getStore} from "../../redux/selectors"
// components
import UserAvatar from '../../components/UserAvatar/UserAvatar'
import Header from '../../components/Header'
import UserNav from '../../components/Header/UserNav'
import BottomNav from '../../components/Navigation/BottomNav'
import LoadingIndicator from '../../lib/helpers/LoadingIndicator'

import {
  H1,
  CenteredText,
  Spacer,
} from '../../lib/styles/typography'

import {
  PageContent
} from '../../lib/styles/layout'

import './UserPage.css'
import ListWorlds from '../../components/World/ListWorlds/ListWorlds'

import {
  UserStatsWrapper,
  UserStats,
  Stat,
} from './styles'


function UserPage(props) {

  let _loadingUser = false

  const [user, setUser] = useState(null)
  const [worldsCreated, setWorldsCreated] = useState(null)

  useEffect(() => {

    const _loadUser = async () => {
      try {
        const user = await getPublicUser(props.match.params.id)
        setUser(user.user)
      } catch(err) {
        console.error(err)
      }
    }

    !_loadingUser && _loadUser()

    return () => _loadingUser = true
  }, [])

  const followThisUser = async (e) => {
    e.preventDefault();

    const response = await followUser(
      user.id,
      props.user.id,
      props.user.token
    );

    if (response && true === response.success) {
      const _user = {...user}
      _user.followers = response.followers
      setUser(_user)
    }
  }

  return (<Fragment>
    <Header
      history={props.history}
      left
      right={() => {
        if (user && props.user.id !== user.id) {
          return null
        }

        return user && user.id
          ? <UserNav org={api.org} />
          : false
      }}
      title={`@${user && user.username}`}
    />

    {!user
      ? <LoadingIndicator />
      : <PageContent
        height={'auto'}
      >

      <UserStatsWrapper>
        {user.id
          && <UserAvatar
            link={false}
            size={96}
            userObj={user}
          />}

        <UserStats>
          {user.followers
            && <Stat>
              <Link
                to={{
                  pathname: `/user-followers/`,
                  state: {
                    followers: user.followers,
                    title: 'Followers'
                  }
                }}
              >
                <h3>{user.followers.length}</h3>
                <h6>Followers</h6>
              </Link>
            </Stat>}

          {user.following
            && <Stat>
              <Link
                to={{
                  pathname: `/user-followers/`,
                  state: {
                    followers: user.following,
                    title: 'Following'
                  }
                }}
              >
                <h3>{user.following.length}</h3>
                <h6>Following</h6>
              </Link>
            </Stat>}

          {0 < worldsCreated
            && <Stat>
              <h3>{worldsCreated}</h3>
              <h6>Worlds</h6>
            </Stat>}
        </UserStats>
      </UserStatsWrapper>

          {user && (props.user.id !== user.id)
            && <div className={'follow-btn pwp-align-center'}>
              {(-1 === user.followers.indexOf(+props.user.id))
                ? <button
                  className={'btn primary small'}
                  onClick={followThisUser}>
                  Follow
                </button>
                : <button
                  className={'btn disabled small'}
                  disabled={true}>
                  Following
                </button>}
            </div>}

          <H1>{user.name}</H1>

          {"" !== user.description
            && <div className={'user-description'}>
              {user.description}
            </div>}
      </PageContent>}

    {user
      && (props.user.id === user.id)
      && <CenteredText>
        {(!api.org || '' === api.org)
            && <Link
              className={'link'}
              to={'/org/new'}>
              Create an Organization
            </Link>}
          {(api.org
            && '' !== api.org
            && props.user.currentUser.isAdmin)
            ? <>
              <Link
                className={'link'}
                to={'/org/edit-organization'}>
                Manage Organization
              </Link>
            </>
            : false}
      </CenteredText>}

    <Spacer
      height={'5%'}
    />

    {user
      && user.id
      && <ListWorlds
        linkUser={false}
        forceRefresh={true}
        forUser={user.id}
        showEditLink
        onWorldsLoaded={(worlds, pagination) => {
          setWorldsCreated(pagination.worlds_found)
        }}
      />}

    <BottomNav />
  </Fragment>)
}

export default connect(getStore, {addError, addActivityIndicator})(UserPage);
