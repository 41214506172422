import React, { Component } from 'react';
// redux
import { connect } from 'react-redux'
// import {addError, addActivityIndicator} from "../../../redux/actions"
// api
// import {getCategories} from '../../../boxappapi/tax_client'
// components
// import ExpandableContainer from '../../../lib/helpers/ExpandableContainer'
// import InputField from '../../../lib/helpers/InputField'
import EditCategories from './EditCategories'

class EditTaxonomies extends Component {

  render() {
    return (
      <div className="edit-toxonomies">

        <EditCategories
          worldID={this.props.worldID}
          user={this.props.user}
          updateWorld={this.props.updateWorld} />
      </div>
    );
  }
}

export default connect(null, {})(EditTaxonomies);
