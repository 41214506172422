import React, { useState, useEffect } from 'react';


import ObjectsAPIClient from '../../../boxappapi/objects_client';

import { connect } from 'react-redux'
import {getStore} from "../../../redux/selectors"
import {
  addActivityIndicator,
  addError,
} from "../../../redux/actions"

import LoadingIndicator from '../../../lib/helpers/LoadingIndicator'
import ListObjects from '../ListObjects/ListObjects'

const InsertObjects = (props) => {

	const [objects, setObjects] = useState( null )

	useEffect( () => {
		getObjects()
	}, [] )

	const getObjects = async () => {
		try {
			const _objects = await ObjectsAPIClient.getObjects(
				props.worldID
			)
			setObjects( _objects )
		} catch( err ) {
			console.error(err)
			props.addError( err )
		}
	}

	const reloadObjects = getObjects

  if (!objects) {
  	return (<LoadingIndicator />)
  }

	return (<div>
    <ListObjects
      worldID={props.worldID}
      updatePreview={props.updatePreview}
      objects={objects}
      reloadObjects={reloadObjects}
    />
	</div>)
}


export default connect(getStore, {
  addActivityIndicator,
  addError,
})(InsertObjects);
